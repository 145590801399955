import React, { useState, useEffect } from 'react';
import { TopBlock } from './TopBlock';
import { MainBlock } from './MainBlock';
import useGlobal from "../../store/index";
import { BottomNavigationWrapper } from "../Shared/BottomNavigationWrapper/BottomNavigationWrapper";
import { SideFloatingButtons } from "./SideFloatingButtons";
import './Home.css';



export const Home = () => {
  const [toggleWeather, setToggleWeather] = useState(false);
  const [togglePumps, setTogglePumps] = useState(false);

  const [currentTerritoryId, territoryService] = useGlobal(
    store => store.currentTerritoryId,
    actions => actions.territory.territoryService
  );

  const { getTrackers, getTrackerGroups, getTrackersForMap } = territoryService();

  useEffect(() => {
    getTrackerGroups();
    getTrackers();
    getTrackersForMap();
  }, [currentTerritoryId])

  return (
    <div className="">
      <SideFloatingButtons />
      <TopBlock toggleWeather={toggleWeather} togglePumps={togglePumps} />
      <MainBlock />
      <BottomNavigationWrapper />
    </div>
  );
}
