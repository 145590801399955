import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SideBarComponent } from './SideBar/SideBarComponent';
import { Home } from '../../components/Home/Home';
import { HistoryComponent } from '../../components/History/HistoryComponent';
import { CalendarComponent } from '../../components/Calendar/CalendarComponent';
import { MenuToggleButton } from '../Common/MenuToggleButton';

import { UserAccountComponent } from '../Account/UserAccountComponent';

import { createAuthProvider } from '../../providers/authProvider';
import { useSocket } from "../../providers/createSocket";
import useGlobal from "../../store";
export const Layout = () => {

	const [currentTerritoryId, territoryService] = useGlobal(
		store => store.currentTerritoryId,
		actions => actions.territory.territoryService
	);
	const { initCurrentTerritoryId } = territoryService();
	const { useAuth } = createAuthProvider();

	const { createConnection } = useSocket();
	const [isLogged] = useAuth();
	useEffect(() => {
		if (isLogged) {
			initCurrentTerritoryId();
			createConnection();
		}
	}, [isLogged]);

	if (!isLogged)
		return <Redirect to="/login" />

	return (
		<div className="container-fluid w-auto">

			<SideBarComponent />
			<div className={"content col"}>

				<MenuToggleButton />
				<Route exact path='/' component={Home} />
				<Route path='/history' component={HistoryComponent} />
				<Route path='/calendar' component={CalendarComponent} />
				<Route path='/account' component={UserAccountComponent} />
			</div>
		</div>
	);
}