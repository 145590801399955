import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export const CircularProgress = (props) => {

    const color = props.color || "#2160b5";
    const height = props.height || 50;
    const width = props.width || 50;
    const useSpan = props.useSpan || false;
    return (
        useSpan ? <Loader
            type="TailSpin"
            color={color}
            height={height}
            width={width} //3 secs
        /> :
            <div className="container-fluid m-1 h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <Loader
                        type="TailSpin"
                        color={color}
                        height={height}
                        width={width} //3 secs
                    />
                </div>
            </div>
    );
}