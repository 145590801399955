import { apiProxy } from "../hooks/apiProxy";
export const pumpScheduleService = (store) => {
    const { get, patch, post, remove } = apiProxy();

    const getPumpSchedulesInCurrentTerritory = async () => {
        const pumpSchedules = {
            data: [],
            isLoading: true
        }
        store.setState({ pumpSchedules });
        try {
            const response = await get(`territories/${store.state.currentTerritoryId}/pumpschedules`);
            const pumpSchedules = {
                data: response===""?[]:response,
                isLoading: false
            }
            store.setState({ pumpSchedules });
        } catch (error) {
            console.log(error);
            const pumpSchedules = {
                data: [],
                isLoading: false
            }
            store.setState({ pumpSchedules });
        }
    }

    const createPumpSchedule = async (payload) => {
        try {
            return await post(`pumpschedules/`, payload);
        } catch (error) {
            throw error;        
        }
    }

    const updatePumpSchedule = async (payload) => {
        try {
            console.log(payload);
           return await patch(`pumpschedules/${payload.id}/`, payload);
        } catch (error) {
            throw error;
        }
    }


    const deletePumpSchedule = async (pumpScheduleId) => {
        try {
            await remove(`pumpschedules/${pumpScheduleId}/`);
        } catch (error) {            
            throw error;
        }
    }

    const pushNewPumpScheduleInState = (schedule) => {
        const newSchedules = { ...store.state.pumpSchedules };
        //const groups = {...store.state.trackerGroups};
        newSchedules.data.push(schedule);

        store.setState({ pumpSchedules: newSchedules });
    }

    const updatePumpScheduleInState = (schedule)=>{
        const newSchedules = { ...store.state.pumpSchedules };

        for(let i=0; newSchedules.data.length > i; i++){
            if(newSchedules.data[i].id===schedule.id){
                newSchedules.data[i].is_enabled = schedule.is_enabled;
                newSchedules.data[i].title = schedule.title;
                newSchedules.data[i].start_time = schedule.start_time;
                newSchedules.data[i].end_time = schedule.end_time;
                newSchedules.data[i].start_date = schedule.start_date;
                newSchedules.data[i].end_date = schedule.end_date;
                newSchedules.data[i].days = schedule.days;
                newSchedules.data[i].pumps = schedule.pumps;
            }
        }

        store.setState({ pumpSchedules: newSchedules });
    }

    const removePumpScheduleFromState = (scheduleId) => {
        const newSchedules = { ...store.state.pumpSchedules };

        newSchedules.data = newSchedules.data.filter((e) => e.id !== scheduleId);

        store.setState({ pumpSchedules: newSchedules });
    }

    return { createPumpSchedule, deletePumpSchedule, getPumpSchedulesInCurrentTerritory, updatePumpSchedule, pushNewPumpScheduleInState, updatePumpScheduleInState, removePumpScheduleFromState }
}