import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CreateShcedule } from "../Schedule/CreateShcedule";
import { CreatePumpSchedule } from "../Pumps/CreatePumpSchedule";
import Moment from "moment";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import './CalendarComponent.css';
import useGlobal from '../../store';

export const CalendarComponent = () => {
    const [showAddNewScheduleModal, toggleShowAddNewScheduleModal] = useState(false);
    const [showAddNewPumpScheduleModal, toggleShowAddNewPumpScheduleModal] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(undefined);
    const { width } = useWindowDimensions();

    const [currentTerritoryId, territoryService] = useGlobal(
        store => store.currentTerritoryId,
        actions => actions.territory.territoryService
    );

    const [schedules, groupScheduleService] = useGlobal(
        state => state.schedules,
        action => action.groupSchedules.groupScheduleService
    );

    const [pumps, pumpService] = useGlobal(
        state => state.pumps,
        action => action.pumps.pumpService
    );

    const [pumpSchedules, pumpScheduleService] = useGlobal(
        state => state.pumpSchedules,
        action => action.pumpSchedules.pumpScheduleService
    )

    const [trackerGroupData] = useGlobal(
        state => state.trackerGroups,
    );
    const { getTrackerGroups } = territoryService();
    const { getGroupSchedulesInCurrentTerritory } = groupScheduleService();
    const { getPumpSchedulesInCurrentTerritory } = pumpScheduleService();
    const { getInCurrentTerritory } = pumpService();
    useEffect(() => {
        getGroupSchedulesInCurrentTerritory();
        getPumpSchedulesInCurrentTerritory();
        getTrackerGroups();
        getInCurrentTerritory();
    }, [currentTerritoryId])

    let scheduleEvents = [];
    if (Array.isArray(schedules.data)) {
        schedules.data.forEach((item) => {

            let startDate = Moment(Moment(item.start_date).format("YYYY-MM-DD"));
            let endDate = Moment(Moment(item.end_date).format("YYYY-MM-DD"));
            let diff = endDate.diff(startDate, 'days');

            let groupColor = "#000000";
            let groupName = "";
            if (!trackerGroupData.isLoading) {
                if (Array.isArray(item.tracker_groups) && item.tracker_groups.length > 0) {
                    let group = trackerGroupData.data.find(x => x.id == item.tracker_groups[0].id);
                    if (group && group.color) {
                        groupColor = group.color;
                    }

                    if (group && group.name) {
                        groupName = group.name;
                    }
                }
            }

            if (item.days.length == 0)
                return;

            for (let i = 0; i <= diff; i++) {
                let tmpDay = Moment(startDate).add(i, 'days').format('YYYY-MM-DD');
                let dayOfWeek = Moment(tmpDay).day();
                if (item.days.includes(dayOfWeek)) {

                    scheduleEvents.push({
                        title: `${item.title} ${groupName}`,
                        date: tmpDay,
                        backgroundColor: groupColor,
                        start: Moment(`${tmpDay} ${item.start_time}`).format("YYYY-MM-DD HH:mm:ss"),
                        end: Moment(`${tmpDay} ${item.end_time}`).format("YYYY-MM-DD HH:mm:ss"),
                        target: item,
                        display: 'block'
                    });
                }
            }
        });
    }

    if (Array.isArray(pumpSchedules.data)) {
        pumpSchedules.data.forEach((item) => {

            let startDate = Moment(Moment(item.start_date).format("YYYY-MM-DD"));
            let endDate = Moment(Moment(item.end_date).format("YYYY-MM-DD"));
            let diff = endDate.diff(startDate, 'days');

            let groupName = "";
            if (!pumps.isLoading) {
                let pump = pumps.data.find(x => x.id == item.id);

                if (pump && pump.title) {
                    groupName = pump.title;
                }
            }

            if (item.days.length == 0)
                return;

            for (let i = 0; i <= diff; i++) {
                let tmpDay = Moment(startDate).add(i, 'days').format('YYYY-MM-DD');
                let dayOfWeek = Moment(tmpDay).day();
                if (item.days.includes(dayOfWeek)) {

                    scheduleEvents.push({
                        title: `${item.title} : ${groupName}`,
                        date: tmpDay,
                        start: Moment(`${tmpDay} ${item.start_time}`).format("YYYY-MM-DD HH:mm:ss"),
                        end: Moment(`${tmpDay} ${item.end_time}`).format("YYYY-MM-DD HH:mm:ss"),
                        target: item,
                        display: 'block'
                    });
                }
            }

        });
    }

    let leftSide = "prev,next,today addGroupScheduleButton,addPumpScheduleButton";
    let center = "title"
    let rightSide = "dayGridMonth,dayGridWeek,dayGridDay";

    if (width<786) {
        console.log("HH");
        leftSide = "addGroupScheduleButton,addPumpScheduleButton";
        rightSide = "title";
         center = "prev,next,today dayGridMonth,dayGridWeek,dayGridDay";
    }


    return <div className="calendar-wrapper">
        <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
                left: leftSide,
                center: center,
                right: rightSide
            }}
            customButtons={
                {
                    addGroupScheduleButton: {
                        text: "Add Group Schedule",
                        click: () => {
                            toggleShowAddNewScheduleModal(true);
                        }
                    },
                    addPumpScheduleButton: {
                        text: "Add Pump Schedule",
                        click: () => {
                            toggleShowAddNewPumpScheduleModal(true);
                        }
                    }
                }
            }
            events={scheduleEvents}
            height="auto"
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false
            }}
            eventClick={(info) => { console.log(info.event.target); }}
        />
        <CreateShcedule scheduleId={selectedScheduleId} show={showAddNewScheduleModal} toggleShowAddNewScheduleModal={toggleShowAddNewScheduleModal} />
        <CreatePumpSchedule show={showAddNewPumpScheduleModal} toggleShowAddNewPumpScheduleModal={toggleShowAddNewPumpScheduleModal} />

    </div>;
}