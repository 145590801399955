import React from 'react';
import { useBottomNavigation } from "../../hooks/useBottomNavigation";
import { ScheduleList } from "./ScheduleList";
import "./ScheduleMobile.css";


export const ScheduleMobile = () => {
    const [seletedTab] = useBottomNavigation();

    if (seletedTab !== "schedule")
        return "";

    return (
        <div className="schedule-mobile-wrapper">
            <ScheduleList />
        </div>
    )
}
