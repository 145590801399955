import { apiProxy } from "../hooks/apiProxy";
import { trackerGroup } from ".";


export const trackerGroupService = (store) => {
    const { get, post, patch, remove } = apiProxy();

    const createTrackerGroup = async (payload) => {
        try {
            const response = await post("trackergroups/", payload)
        } catch (error) {
            console.log(error);
        }
    }

    const updateTrackerGroup = async (payload) => {
        try {
            const response = await patch(`trackergroups/${payload.id}/`, payload)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteTrackerGroup = async (id) => {
        try {
            const response = await remove(`trackergroups/${id}/`);
        } catch (error) {
            console.log(error);
        }
    }

    const pushTrackerGroupToState = (group) => {
        const newGroups = { ...store.state.trackerGroups };

        newGroups.data.push(group);

        store.setState({ trackerGroups: newGroups });
    }


    const updateTrackerGroupToState = (group) => {
        const newGroups = { ...store.state.trackerGroups };

        for (let i = 0; i < newGroups.data.length; i++) {
            if (newGroups.data[i].id == group.id) {
                newGroups.data[i].color = group.color;
                newGroups.data[i].name = group.name;
            }
        }

        store.setState({ trackerGroups: newGroups });
    }

    const removeTrackerGroupFromState = (groupId) => {
        const newGroups = { ...store.state.trackerGroups };
       
        newGroups.data = newGroups.data.filter((e) => e.id != groupId);

        store.setState({ trackerGroups: newGroups });
    }
    
    return { 
        createTrackerGroup,
        updateTrackerGroup, 
        deleteTrackerGroup, 
        pushTrackerGroupToState, 
        updateTrackerGroupToState, 
        removeTrackerGroupFromState  
    };
}