import Utils from "../Utils";
import axios from "axios";
import { post } from "jquery";

let tokenStr = localStorage.getItem('REVALCON_AUTH') || null;
let _token = JSON.parse(tokenStr) || null;
export const createTokenProvider = () => {
    let _baseUrl = Utils.apiAdminBaseUrl;
    const getExpirationDate = (jwtToken) => {
        if (!jwtToken) {

            return null;
        }
        const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
        return jwt && jwt.exp && jwt.exp * 1000 || null;
    };

    const clearStore = () => {
        localStorage.removeItem("REVALCON_AUTH");
    }

    const isExpired = (exp) => {
        if (!exp) {

            return false;
        }

        return Date.now() > exp;
    };

    const getToken = () => {
        if (!_token) {
            return null;
        }

        //if (isExpired(getExpirationDate(_token.token))) {
//
        //    await fetch(`${_baseUrl}api/Account/Refresh?AccessToken=${_token.token}&RefreshToken=${_token.refreshToken}`, {
        //        method: 'POST',
        //        headers: {
        //            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        //        },
        //    })
        //        .then(r => {
        //            r.json().then(rr => {
        //                setToken(rr);
        //            });
        //        })
        //        .catch(error => {
        //            console.log(error);
        //            window.location = "./login";
        //        });
        //}
//
        // if (isExpired(getExpirationDate(_token.token))) {
        //     //debugger;
        //     //, {
        //         //method: 'POST',
        //         // body: {
        //         //     refreshToken:_token.refreshToken,
        //         //     token: _token.token
        //         // }
        //     //}
        //     //const updatedToken = await 
        //     const updatedToken= await axios.post(`${_baseUrl}api/Account/Refresh?AccessToken=${_token.token}&RefreshToken=${_token.refreshToken}`)
        //                                     .then(r =>r.json())
        //                                     .catch((error)=>{
        //                                          window.location="/login"
        //                                     });

        //     //setToken(updatedToken);
        // }

        return _token && _token.token;
    };

    const isLoggedIn = () => {
        return !!_token;
    };

    let observers = [];

    const subscribe = (observer) => {
        observers.push(observer);
    };

    const unsubscribe = (observer) => {
        observers = observers.filter(_observer => _observer !== observer);
    };

    const notify = () => {
        const isLogged = isLoggedIn();
        observers.forEach(observer => observer(isLogged));
    };

    const refreshToken =  () => {
        if (!_token) {
            return null;
        }

        fetch(`${_baseUrl}api/Account/Refresh?AccessToken=${_token.token}&RefreshToken=${_token.refreshToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
        })
            .then(r => {
                r.json().then(rr => {
                    clearStore();
                    setToken(rr);
                });
            })
            .catch(error => {
                console.log(error);
                clearStore();
                window.location = "./login";
            });
    }

    const setToken = (token) => {
        if (token) {
            localStorage.setItem('REVALCON_AUTH', JSON.stringify(token));
        } else {
            localStorage.removeItem('REVALCON_AUTH');
        }
        _token = token;
        notify();
    };

    return {
        getToken,
        isLoggedIn,
        setToken,
        subscribe,
        unsubscribe,
        clearStore,
        refreshToken
    };
};