import React from "react";
import useGlobal from "../../../store";
import moment from "moment";
import { createWatchCompilerHost } from "typescript";

export const WateringInfo = (props) => {

    const [schedules] = useGlobal(
        state => state.schedules
    )
    const [trackerSchedules] = useGlobal(
        state => state.trackerSchedules
    )
    let groupSchedules = [];
    schedules.data.forEach(item => {
        let startDate = moment(moment(item.start_date).format("YYYY-MM-DD"));
        let endDate = moment(moment(item.end_date).format("YYYY-MM-DD"));
        let diff = endDate.diff(startDate, 'days');

        if (item.days.length == 0)
            return;

        for (let i = 0; i <= diff; i++) {
            let tmpDay = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
            let dayOfWeek = moment(tmpDay).day();
            if (item.days.includes(dayOfWeek)) {

                groupSchedules.push({        
                    start: moment(`${tmpDay}T${item.start_time}`),
                    end: moment(`${tmpDay}T${item.end_time}`),
                    target: item,
                    tracker_groups: item.tracker_groups,
                    display: 'block'
                });
            }
        }
    })
    let currentTrackerSchedules = groupSchedules.filter(x => x.tracker_groups.some(y => y.id == props.tracker.group.id));
    let wateringStartTime = moment().add(0, "minutes");
    
    let isNowWateringByGroupSchedule = currentTrackerSchedules.some(x => wateringStartTime >= x.start && wateringStartTime <= x.end);

    let isNowWateringByTrackerSchedule = trackerSchedules.data.some(x =>  
                                         {
                                            let startDateTime = moment(x.start_datetime).local();
                                            return    x.title == props.tracker.title && 
                                            x.node_id == props.tracker.node_id && 
                                            wateringStartTime >= startDateTime && 
                                            wateringStartTime <= startDateTime.add(x.hours, 'hours').add(x.minutes, 'minutes');

                                         });

    if (isNowWateringByTrackerSchedule)
        return "Currently manual watering";
    else if (isNowWateringByGroupSchedule)
        return "Currently schedule watering";
    else
        return "";
}