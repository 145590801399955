import { apiProxy } from './apiProxy';


export const useTrackerServer = () => {
    const {  get } = apiProxy();

    const loadTrackerServer = async (trackerServerId) => {
        return await get(`servers/${trackerServerId}`);
    }

    return { loadTrackerServer };
}