import React from 'react';
import { CircularProgress } from "../Common/CircularProgress";
import useGlobal from "../../store/index";

import { InfoWindowWrapper } from "./InfoWindowWrapper";
import { Polygones } from './Polygones';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { NetworkInformation } from '../NetworkInformation/NetworkInformation'


import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { compose, withProps } from "recompose"



export const MapWithGroundOverlay = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDzCEkax9RoRU7-dREsfJKkl-WsZdI8SjY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {

  return <GoogleMap
    defaultZoom={14}
    defaultCenter={props.defaultCenter}
    center={props.defaultCenter}
    defaultOptions={{ mapTypeControl: false, streetViewControl: false }}
    mapTypeId={'satellite'}
  >
    <NetworkInformation />
    <Polygones />

    <InfoWindowWrapper />
  </GoogleMap>
});