import React, { useState, useEffect } from 'react';
import Icons8Play from '../../assets/img/icons8-play.svg'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";

import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FolderIcon from '@material-ui/icons/Folder';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import useGlobal from "../../store/index";
import moment from "moment";

import './ScheduleComponent.css';



export const ScheduleComponent = (props) => {
    let schedule = props;
    const [open, setOpen] = useState(false);
    const [scheduleGroupColor, setScheduleGroupColor] = useState("#FFFFFF");
    const [scheduleGroupName, setScheduleGroupName] = useState("");
    const [canStartStop, setCanStartStop] = useState(false);
    const [trackerGroups] = useGlobal(
        state => state.trackerGroups
    );

    const weekDaysMap = {
        1: "M",
        2: "T",
        3: "W",
        4: "Th",
        5: "F",
        6: "S",
        0: "Su "
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            height: 380,
            transform: 'translateZ(0px)',
            flexGrow: 1,
        },
        speedDial: {
            position: 'absolute',
            height: 40,
            width: 40,
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
        startButton: {
            color: "#2160b5"
        },
        stopButton: {
            color: "#ff5440"
        },
        iconBgColor: {
            color: scheduleGroupColor
        },
    }));

    const classes = useStyles();
    useEffect(() => {
        setScheduleGroupColor(schedule.nodeColor)
        setScheduleGroupName(schedule.nodeType);
        let endDateTime = moment(`${schedule.end_date}T${schedule.end_time}`);
        let now = moment();
        setCanStartStop(now.isBefore(endDateTime));
    }, [])

    const [trackerGroupData, getTrackerGroups] = useGlobal(
        state => state.trackerGroups,
        actions => actions.territory.useGetTrackerGroups
    );
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const onDeleteClick = () => {
        handleClose();
        props.onDelete(schedule.scheduleId);
    }

    const onEditClick = () => {
        props.onEdit(schedule);
    }



    return (
        <div className={`event ${!schedule.is_enabled ? "stoped" : ""}`} key={schedule.scheduleId} >
            {schedule.scheduleType != 'Tracker' ?
                <SpeedDial
                    ariaLabel="SpeedDial openIcon"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    direction="left"
                    open={open}
                >
                    {schedule.is_confirmed ? <SpeedDialAction
                        key={"Edit"}
                        icon={<EditIcon fontSize="small" />}
                        tooltipTitle={"Edit"}
                        onClick={onEditClick}
                    /> : ""}

                    <SpeedDialAction
                        key={"Delete"}
                        icon={<DeleteForeverIcon fontSize="small" />}
                        tooltipTitle={"Delete"}
                        onClick={onDeleteClick}
                    />

                    {
                        schedule.is_confirmed && canStartStop ? (schedule.is_enabled ? <SpeedDialAction
                            className={classes.startButton}
                            key={"Stop"}
                            icon={<PauseCircleFilledRoundedIcon fontSize="small" className={classes.stopButton} />}
                            tooltipTitle={"Stop"}
                            onClick={props.onPause} />
                            :
                            <SpeedDialAction
                                className={classes.startButton}
                                key={"Start"}
                                icon={<PlayCircleFilledIcon className={classes.startButton} fontSize="small" />}
                                tooltipTitle={"Start"}
                                onClick={props.onPlay} />) : ""
                    }
                </SpeedDial> : ""}

            <p>{schedule.eventName}</p>
            <p>
                {
                    schedule.scheduleType == "Groups" ? <span >
                        <FolderIcon className={classes.iconBgColor} />
                    </span> : ""
                }

                <span>{scheduleGroupName}</span>
            </p>
            {
             schedule.scheduleType=="Tracker"?
                            <span>{moment().format("DD.MM.yyyy")}</span>
                            :<span>{moment(schedule.start_date).format("DD.MM.yyyy")} {moment(schedule.start_time, "HH:mm:ss").format("HH:mm")} - {moment(schedule.end_date).format("DD.MM.yyyy")} {moment(schedule.end_time, "HH:mm:ss").format("HH:mm")}</span>
            }
            
            <div className="block-text">
                <p style={{ display: "flex" }}>
                    {schedule.scheduleType != "Tracker" ? schedule.days.map(s => <span key={s} className={"weekDay"}>{weekDaysMap[s]}</span>) : ""}

                    {schedule.description}
                </p>
            </div>

            <div className="block_start_stop">
                {/*start for mobile*/}
                <div className="start">
                    <button type="button">
                        <img src={Icons8Play} className="icons8-play" />
                    </button>
                    <span>Start</span>
                </div>
            </div>
        </div>
    )
}