import axios from 'axios';
import Utils from '../Utils';
class Api {
    static baseUrl = Utils.apiBaseUrl;
 
    static headers() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'dataType': 'json',
        }
    }

    static get(route) {
        return this.xhr(route);
    }
    static put(route, params) {
        return this.xhr(route, params, 'PUT')
    }
    static post(route, payload) {
        return axios.post(this.baseUrl+route,  payload )      
            .then((responseJson) => {
                return responseJson;
            })
            .catch((error) => {
                return error;
                console.error(error);
            });
    }
    static delete(route, params) {
        return this.xhr(route, params, 'DELETE')
    }
    static xhr(url) {
        return fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                debugger;
                return responseJson;
            })
            .catch((error) => {
                debugger;
                console.error(error);
            });

    }
}
export default Api