import { useState, useEffect } from 'react';

let mobileTrackerGroupSelectListeners = [];
let selectedMobileTrackerGroup = false;
const setMobileTrackerGroup = (newState) => {
    selectedMobileTrackerGroup = newState;
    mobileTrackerGroupSelectListeners.forEach((listener) => {
        listener(selectedMobileTrackerGroup);
    });
};

export default function useMobileTrackerGroupSelect() {
    const newListener = useState()[1];
    useEffect(() => {
        mobileTrackerGroupSelectListeners.push(newListener);
        return () => {
            mobileTrackerGroupSelectListeners = mobileTrackerGroupSelectListeners.filter(listener => listener !== newListener);
        };
    }, []);
    return [selectedMobileTrackerGroup, setMobileTrackerGroup];
}