import { apiProxy } from "../hooks/apiProxy";

export const territoryService = (store) => {
    const getById = async (territoryId) => {
        const { get } = apiProxy();
        try {
            const response = await get(`territories/${territoryId}/`);
            const terriroty = response;
            store.setState({ terriroty });
        } catch (error) {
            //const isError404 = error.response && error.response.status === 404;
            //const status = isError404 ? "NOT_FOUND" : "ERROR";        
            console.log(error);
        }
    }

    const initCurrentTerritoryId = async () => {
        let territories = await getTerritories();
        let currentTerritoryId = store.state.territories.data[0].id;
        let trackerServer = await getTrackerServer(currentTerritoryId);
        if (trackerServer &&
            trackerServer.location &&
            Array.isArray(trackerServer.location) &&
            trackerServer.location.length > 0) {
            setMapCenter({ lat: trackerServer.location[0].lat, lng: trackerServer.location[0].lng });
        }
        store.setState({ currentTerritoryId });
    }

    const getTerritories = async () => {
        const { get } = apiProxy();
        const territories = {
            isLoading: true,
            data: []
        }
        store.setState({ territories });
        try {
            const response = await get(`territories/`);

            const territories = {
                data: response,
                isLoading: false
            }
            //store.setState({ currentTerritoryId: response[0].id });
            store.setState({ territories });
        } catch (error) {
            console.log(error);
            const territories = {
                data: [],
                isLoading: false
            }
            store.setState({ territories });
        }
    }

    const setCurrentTerritory = async (item) => {
        let trackerServer = await getTrackerServer(item.id);
        if (!trackerServer) {
            return;
        }

        setMapCenter({ lat: trackerServer.location[0].lat, lng: trackerServer.location[0].lng });
        store.setState({ currentTerritoryId: item.id });
        store.state.hubConnection.send(JSON.stringify({
            message: {
                action: "getinitialstate",
                data: {
                    currentTerritoryId: item.id
                }
            }
        }));
    }

    const setMapCenter = (center) => {
        store.setState({ mapCenterPosition: center });
    }

    const getTrackerServer = async (territoryId) => {
        const { get } = apiProxy();
        const trackerServers = {
            data: [],
            isLoading: true
        }
        store.setState({ trackerServers });

        try {
            const response = await get(`territories/${territoryId}/servers/`);
            const trackerServers = {
                isLoading: false,
                data: response
            };
            const trackerServer = trackerServers.data[0];
            //setMapCenter({ lat: trackerServer.location[0].lat, lng: trackerServer.location[0].lng });
            store.setState({ trackerServers, trackerServer });
            return trackerServer;
        } catch (error) {
            console.log(error);
            const trackerServers = {
                isLoading: false,
                data: []
            };
        }
    }

    const getTrackerGroups = async () => {
        const { get } = apiProxy();
        const trackerGroups = {
            data: [],
            isLoading: true
        }
        store.setState({ trackerGroups });

        try {
            const response = await get(`territories/${store.state.currentTerritoryId}/trackergroups/`);
            const trackerGroups = {
                data: response,
                isLoading: false
            }
            store.setState({ trackerGroups });
        } catch (error) {
            console.log(error);
            const trackerGroups = {
                data: [],
                isLoading: false
            }
            store.setState({ trackerGroups });
        }
    }

    const getTrackers = async () => {
        const { get } = apiProxy();
        const trackers = {
            data: [],
            isLoading: true
        }
        store.setState({ trackers });
        try {
            const response = await get(`territories/${store.state.currentTerritoryId}/trackers`);
            response.forEach((item) => {
                item.isWaiting = false;
            });
            response.forEach(item=>{
                item.water_pressure = item.sensor1
            });
            
            const trackers = {
                data: response,
                isLoading: false
            }
            store.setState({ trackers });
        } catch (error) {
            console.log(error);
            const trackers = {
                data: [],
                isLoading: false
            }
            store.setState({ trackers });
        }
    }

    const getTrackersForMap = async () => {
        const { get } = apiProxy();
        const trackersForMap = {
            data: [],
            isLoading: true
        }

        store.setState({ trackersForMap });
        store.setState({ trackersForMapIsLoading: trackersForMap.isLoading });
        try {
            const response = await get(`territories/${store.state.currentTerritoryId}/trackers`);

            response.forEach((item) => {
                item.isWaiting = false;
                item.water_pressure = item.sensor1;
            });
            
            const trackersForMap = {
                data: response,
                isLoading: false
            }
            store.setState({ trackersForMapIsLoading: trackersForMap.isLoading });

            store.setState({ trackersForMap });
        } catch (error) {
            console.log(error);
            const trackersForMap = {
                data: [],
                isLoading: false
            }
            store.setState({ trackersForMapIsLoading: trackersForMap.isLoading });

            store.setState({ trackersForMap });
        }
    }

    return { getById, getTrackerServer, getTrackerGroups, getTrackers, getTrackersForMap, getTerritories, setCurrentTerritory, setMapCenter, initCurrentTerritoryId }
}