import { apiProxy } from "../hooks/apiProxy";


export const useHubCallBack = (store, territoryId, trackerId, state) => {
    const { get } = apiProxy();

    get(`trackers/${trackerId}`)
        .then(r => {
            const selectedTracker = r;
            selectedTracker.state = state;
            let bounds = new window.google.maps.LatLngBounds();
            let path = selectedTracker.location;
            path.forEach(element => {
                bounds.extend(element);
            });
            let trackers = store.state.trackers;
            for (let index = 0; index < trackers.data.length; index++) {
                if (trackers.data[index].id == selectedTracker.id) {
                    trackers.data[index] = selectedTracker
                }
            }
            const infoWindowPostion = bounds.getCenter()
            //store.actions.trackerGroup.useSelectGroup(store, selectedTracker.group.id, selectedTracker.id);
            const hubWaitingList = store.state.hubWaitingList.filter(tr => tr.id != trackerId);
            store.setState({ selectedTracker, infoWindowPostion, collapsedTrackers: trackers, hubWaitingList });

        })
        .catch(err => { console.log(err) });
}

export const trackerService = (store) => {

    const { patch, get, post } = apiProxy();

    const updateTracker = async (payload) => {
        let tmpPayload = {
            node_id: payload.node_id,
            title: payload.title,
            description: payload.description,
            group: payload.group,
        }
        try {
            const response = await patch(`trackers/${payload.id}/`, tmpPayload)
        } catch (error) {
            console.log(error);
        }
    }

    const manualStart = async (tracker, payload) => {
        let tmpPayload = {
            tracker_id: tracker.id,
            title: tracker.title,
            hours: payload.hour,
            minutes: payload.minute,
        };

        try {
            //const response = await patch(`trackers/${tracker.id}/manual-start/`, tmpPayload)
            const response = await post(`trackerschedules/`, tmpPayload)
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const setSelectTrackerV2 = (tracker) => {
        const selectedTracker = tracker;
        let bounds = new window.google.maps.LatLngBounds();
        let path = tracker.location;
        path.forEach(element => {
            bounds.extend(element);
        });
        const infoWindowPostion = bounds.getCenter()
        store.setState({ selectedTracker, infoWindowPostion });
    }

    const updateTrackerInState = (tracker) => {

        const newTrackers = { ...store.state.trackers };
        const newTrackersForMap = { ...store.state.trackersForMap }

        for (let i = 0; i < newTrackers.data.length; i++) {
            if (newTrackers.data[i].node_id == tracker.node_id && newTrackers.data[i].valve == tracker.valve) {
                newTrackers.data[i].description = tracker.description;
                newTrackers.data[i].title = tracker.title;
                newTrackers.data[i].node_id = tracker.node_id;
                newTrackers.data[i].opacity = tracker.opacity;
                //newTrackers.data[i].water_pressure = tracker.water_pressure;
                //newTrackers.data[i].battery_level = tracker.BAT;
                //newTrackers.data[i].state = tracker.state;
                newTrackers.data[i].group.id = tracker.group;
                newTrackers.data[i].isWaiting = tracker.isWaiting;

            }
        }

        for (let i = 0; i < newTrackersForMap.data.length; i++) {
            if (newTrackersForMap.data[i].node_id == tracker.node_id && newTrackersForMap.data[i].valve == tracker.valve) {
                newTrackersForMap.data[i].description = tracker.description;
                newTrackersForMap.data[i].title = tracker.title;
                newTrackersForMap.data[i].node_id = tracker.node_id;
                newTrackersForMap.data[i].opacity = tracker.opacity;
                newTrackersForMap.data[i].water_pressure = tracker.water_pressure;
                newTrackersForMap.data[i].battery_level = tracker.BAT;
                //newTrackersForMap.data[i].state = tracker.state;
                newTrackersForMap.data[i].group.id = tracker.group;
                newTrackersForMap.data[i].isWaiting = tracker.isWaiting;
            }
        }

        store.setState({ trackers: newTrackers });
        store.setState({ trackersForMap: newTrackersForMap });
        store.setState({ selectedTrackerGroupId: tracker.group });

    }

    const loadTrackerServer = async (trackerServerId) => {
        return await get(`servers/${trackerServerId}`);
    }

    const updateTrackerStateById = (trackerId, state, valve, battery, pressure, previous_state) => {

        const newTrackers = { ...store.state.trackers };
        const newTrackersForMap = { ...store.state.trackersForMap }
        
        for (let i = 0; i < newTrackers.data.length; i++) {
            if (newTrackers.data[i].node_id == trackerId && newTrackers.data[i].valve == valve) {
                newTrackers.data[i].state = parseInt(state);
                newTrackers.data[i].isWaiting = false;
                newTrackers.data[i].battery_level = battery;
                newTrackers.data[i].water_pressure = pressure;
                newTrackers.data[i].previous_state = previous_state;
                if (parseInt(state) == 1) {
                    newTrackers.data[i].ManualStartDate = new Date();
                }
            }
        }

        for (let i = 0; i < newTrackersForMap.data.length; i++) {
            if (newTrackersForMap.data[i].node_id == trackerId && newTrackersForMap.data[i].valve == valve) {
                newTrackersForMap.data[i].water_pressure = pressure;
                newTrackersForMap.data[i].battery_level = battery;
                newTrackersForMap.data[i].state = parseInt(state);
                newTrackersForMap.data[i].isWaiting = false;
                newTrackersForMap.data[i].previous_state = previous_state;
                if (parseInt(state) == 1) {
                    newTrackersForMap.data[i].ManualStartDate = new Date();
                }
            }
        }

        store.setState({ trackers: newTrackers });
        store.setState({ trackersForMap: newTrackersForMap });
        const newSelectedTracker = { ...store.state.selectedTracker };
        if (newSelectedTracker && newSelectedTracker.node_id == trackerId && newSelectedTracker.valve == valve) {
            newSelectedTracker.state = parseInt(state);
            newSelectedTracker.water_pressure = pressure;
            newSelectedTracker.isWaiting = false;
            newSelectedTracker.battery_level = battery;
            newSelectedTracker.previous_state = previous_state;
            if (parseInt(state) == 1) {
                newSelectedTracker.ManualStartDate = new Date();
            }
            store.setState({ selectedTracker: newSelectedTracker });
        }
    }


    return { setSelectTrackerV2, updateTrackerInState, updateTracker, loadTrackerServer, updateTrackerStateById, manualStart };
}
