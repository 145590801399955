import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Shared/Layout';
import LoginComponent from './components/Account/LoginComponent';
import './custom.css'
const App = () => {


  // // const [currentTerritoryId, getById] = useGlobal(
  // //   store => store.currentTerritoryId,
  // //   actions => actions.territory.useGetById
  // // );


  // hubConnection && hubConnection.on("TrackerChangeState", (territory, trackerId, state) => {
  //   //console.log(`${territory}, ${trackerId}, ${state}`)
  //   hubCallback(territory, trackerId, state);
  // })


  return (
    <div>
      <Layout></Layout>
      <Route path="/login" component={LoginComponent}>
      </Route>
    </div>

  );
}
export default App;
