import { hub, tracker } from ".";

export const useHubConnection = (store, connection) => {
    const hubConnection = connection;

    store.setState({ hubConnection });
}

export const hubWaitingListFactory = (store) => {
    const waitListCleaner = () => {
        setInterval(() => {
            var time = Date.now();
            const trackerToRemove = store.state.hubWaitingList.filter(x => time > x.time + (1000 * 60));
            trackerToRemove.forEach((item) => {

                remove(item.value);
            })
        }, 1000);
    }

    const add = (tracker) => {
        const newhubWaitingList = store.state.hubWaitingList;
        const newTrackersForMap = { ...store.state.trackersForMap }
        const newTrackers = { ...store.state.trackers }
        const newSelectedTracker = { ...store.state.selectedTracker }

        newSelectedTracker.isWaiting = true;

        newhubWaitingList.push({
            value: tracker,
            time: Date.now()
        });

        newTrackersForMap.data.forEach((item) => {
            if (item.id === tracker.id) {
                item.isWaiting = true;
            }
        });

        newTrackers.data.forEach((item) => {
            if (item.id === tracker.id) {
                item.isWaiting = true;
            }
        });

        store.setState({ selectedTracker: newSelectedTracker });
        store.setState({ hubWaitingList: newhubWaitingList });
        store.setState({ trackersForMap: newTrackersForMap });
        store.setState({ trackers: newTrackers });
    }

    const remove = (tracker) => {
        const newTrackersForMap = { ...store.state.trackersForMap }
        const newTrackers = { ...store.state.trackers }
        const newSelectedTracker = { ...store.state.selectedTracker }

        if (newSelectedTracker.id == tracker.id) {
            newSelectedTracker.isWaiting = false;
        }

        newTrackersForMap.data.forEach((item) => {
            if (item.id === tracker.id) {
                item.isWaiting = false;
            }
        });

        newTrackers.data.forEach((item) => {
            if (item.id === tracker.id) {
                item.isWaiting = false;
            }
        });

        const newhubWaitingList = store.state.hubWaitingList.filter(tr => tr.value.id != tracker.id);

        store.setState({ selectedTracker: newSelectedTracker });
        store.setState({ trackersForMap: newTrackersForMap });
        store.setState({ trackers: newTrackers });
        store.setState({ hubWaitingList: newhubWaitingList });
    }


    const removeByTrackerId = (trackerId) => {
        const tracker = store.state.hubWaitingList.find(x => x.id == trackerId).value;

        const newTrackersForMap = { ...store.state.trackersForMap }
        const newTrackers = { ...store.state.trackers }
        const newSelectedTracker = { ...store.state.selectedTracker }

        if (newSelectedTracker.id == tracker.id) {
            newSelectedTracker.isWaiting = false;
        }

        newTrackersForMap.data.forEach((item) => {
            if (item.id === tracker.id) {
                item.isWaiting = false;
            }
        });

        newTrackers.data.forEach((item) => {
            if (item.id === tracker.id) {
                item.isWaiting = false;
            }
        });

        const newhubWaitingList = store.state.hubWaitingList.filter(tr => tr.value.id != tracker.id);

        store.setState({ selectedTracker: newSelectedTracker });
        store.setState({ trackersForMap: newTrackersForMap });
        store.setState({ trackers: newTrackers });
        store.setState({ hubWaitingList: newhubWaitingList });
    }

    const invokeSendMessage = async (selectedTracker, state) => {
        const hubConnection = store.state.hubConnection;

        hubConnection.send(JSON.stringify({
            message: {
                action: "send_action",
                data: {
                    tracker_id: selectedTracker.node_id,
                    valve: selectedTracker.valve,
                    server_id: selectedTracker.server.id,
                    state: state
                }
            }
        }))

        //hubConnection && hubConnection.invoke("SendMessage", `${terriroty.actionTopic}`, `${trackerServer.actionKey}`, [`${selectedTracker.id}`], state)
        //    .then(() => {console.log('Sent   !'); remove(selectedTracker); })
        //    .catch(err => {
        //        console.log(err);
        //        remove(selectedTracker);
        //    });
    }



    return { add, remove, waitListCleaner, invokeSendMessage };
}