import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useGlobal from "../../store/index";
import TablePagination from '@material-ui/core/TablePagination';
import ErrorIcon from "../../assets/img/ic-info.png";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from "moment";

import './HistoryComponent.css';
import { TableFooter } from '@material-ui/core';

import { ScheduleState } from "../Shared/ScheduleState/ScheduleState";
import { ScheduleType } from "../Shared/ScheduleType/ScheduleType";


const useStyles = makeStyles((theme) => (
  {
    table: {
      minWidth: 650,
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 120,
    },
  }
));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export const HistoryComponent = () => {

  const classes = useStyles();

  const [history, historyService] = useGlobal(
    state => state.history,
    actions => actions.history.historyService
  )

  const [companies, companyService] = useGlobal(
    store => store.companies,
    actions => actions.companies.companyService
  );

  const [currentTerritoryId] = useGlobal(
    store => store.currentTerritoryId
  );

  const [territories, territoryService] = useGlobal(
    store => store.territories,
    actions => actions.territory.territoryService
  );

  const { getTerritories, setCurrentTerritory } = territoryService();
  const { getHistory } = historyService();

  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [page, setPage] = useState(0);
  const [location, setLocation] = useState(0);

  useEffect(() => {
    //loadHistory();
    getTerritories();
    setPage(0);
    setRowsPerPage(2);
    setLocation(currentTerritoryId);
    getHistory(currentTerritoryId, 0, 2);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getHistory(location, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let perPage = parseInt(event.target.value);
    setRowsPerPage(perPage);
    setPage(0);
    getHistory(location, 0, perPage);
  };

  const handleChange = (event) => {
    setLocation(event.target.value);
    getHistory(event.target.value, page, rowsPerPage);
  };

  return (
    <div>
      <h4>
        History -
        <FormControl className={classes.formControl}>
          <Select
            value={location}
            onChange={handleChange}
            displayEmpty
            className={`selectTerritory`}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {
              territories.data.map((item) => <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
            }
          </Select>
        </FormControl>
      </h4>

      <div className="history_block">
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="History data">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell >Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell >Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.data.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="right">
                      {row.error ? <img src={ErrorIcon} /> : ""}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell><ScheduleType type={row.type} /></TableCell>
                    <TableCell><ScheduleState state={row.status} /></TableCell>
                    <TableCell>{moment(row.statusChangeTime).format("DD.MM.yyyy HH:mm:ss")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    count={history.totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[2, 3, 10]} />

                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );

}