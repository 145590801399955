import React, { useState, useEffect } from 'react';
import { useBottomNavigation } from "../../hooks/useBottomNavigation";
import { MainBlockGroupList } from "./MainBlockGroupList";
import useGlobal from "../../store/index";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { makeStyles } from '@material-ui/core/styles';
import  useMobileGroupNavigation from "../../hooks/useMobileGroupNavigation";
import useMobileTrackerGroupSelect from "../../hooks/useMobileTrackerGroupSelect";
import { GroupComponentMobile, GroupDetailsComponent, TrackerDetailsComponent } from '../Group/GroupComponentMobile';
import { CreateGroup } from "../Group/CreateGroup";
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import "./GroupsMobile.css";

export const GroupsMobile = () => {
    const [selectedBottonNavigatonTab] = useBottomNavigation();
    const [showAddNewGroupModal, toggleShowAddNewGroupModal] = useState(false);

    const [selectedGroupNavigationTab, setGroupNavigationTab] = useMobileGroupNavigation();
    const [selectedMobileTrackerGroup, setMobileTrackerGroup] = useMobileTrackerGroupSelect();
    const [selectedTracker,setSelectedTracker] = useState(undefined);
    const [selectedLocalTrackerGroupId, setSelectedLocalTrackerGroupId] = useState(undefined);

    useEffect(()=>{
        setGroupNavigationTab("groups");
    },[])

    const [trackerGroupData, getTrackerGroups] = useGlobal(
        state => state.trackerGroups,
        actions => actions.territory.useGetTrackerGroups
    );

    const onAddNewClick = () => {
        toggleShowAddNewGroupModal(true);
    }

    const trackerGroups = {
        groups: trackerGroupData.data,
        isLoading: trackerGroupData.isLoading
    }

    const [selectedTrackerGroupId, setSelectGroup] = useGlobal(
        state => state.selectedTrackerGroupId,
        actions => actions.trackerGroup.useSelectGroup
    );

    const [selectedTrackerId, trackerService] = useGlobal(
        state => state.selectedTrackerId,
        actions => actions.trackers.trackerService
    )

    const {setSelectTrackerV2} = trackerService();

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                // margin: theme.spacing(1),
                width: '25ch',
            },
        },
        form: {
            width: "100%",
        },
        iconButton: {
            padding: 2,
            fontSize: 24,

        },
        startButton: {
            color: "#2160b5"
        },
        stopButton: {
            color: "#ff5440"
        },
        button: {
            color: "#2160b5",
            border: "1px solid #2160b5",
        },
    }));

    const classes = useStyles();

    if (selectedBottonNavigatonTab != "group")
        return "";

    let groupsList = "";

    const navigateTo = (tabName) => {
        setGroupNavigationTab(tabName);
    }

    const onGroupSelect = (group) => {
        setMobileTrackerGroup(group);
    }

    const onSelectTracker = (tracker) =>{
        setSelectedTracker(tracker)
    }

    const onBackSelect = (tabName) => {
        setGroupNavigationTab(tabName);
    }

    const startStopBlock = (trackerId, tracker) => {
        setSelectGroup(selectedTrackerGroupId, trackerId);
        setSelectTrackerV2(tracker);
    }

    const onGroupEdit = (event, trackerGroupId) => {
        event.preventDefault();    
        setSelectedLocalTrackerGroupId(trackerGroupId);
        toggleShowAddNewGroupModal(true);
    }

    if (trackerGroups.groups && trackerGroups.groups.length > 0) {
        groupsList = trackerGroups.groups.map((item, i) => {
            return (<GroupComponentMobile
                navigateTo={navigateTo}
                onGroupSelect={onGroupSelect}
                onEdit= {onGroupEdit}
                key={i}
                collapsed={false}
                trackerGroupId={item.id}
                name={item.name}
                is_editable={item.is_editable}
                description={item.description}
                color={item.color}
                trackerGroup={item} />)
        });
    }

    const style1 = { paddingBottom: "10px !important" };
    let content = (<div style={{overflowY:"auto"}}>
        {groupsList}
        <div className={'float-right'} style={{ marginTop: 10 }}>
                <CreateGroup show={showAddNewGroupModal} toggleShowAddNewGroupModal={toggleShowAddNewGroupModal} trackerGroupId={selectedLocalTrackerGroupId} />
                <Button variant="outlined" className={classes.button} startIcon={<AddIcon />} size="small" color="primary" onClick={onAddNewClick}>
                    Add Groupe
                </Button>
            </div>
    </div>);
    
    if (selectedGroupNavigationTab == "groups") {
        style1.paddingTop = 10;
        style1.paddingLeft = 10;
        style1.paddingRight = 10;
    }
    else if (selectedGroupNavigationTab == "trackers") {
        content = (<GroupDetailsComponent
            navigateTo={navigateTo}
            startStopBlock={startStopBlock}
            color={selectedMobileTrackerGroup.color}
            name={selectedMobileTrackerGroup.name}
            description={selectedMobileTrackerGroup.description} />);
    }
    else if(selectedGroupNavigationTab=="trackerdetils"){
        content=(<TrackerDetailsComponent navigateTo={navigateTo} selectedTrackerGroup={selectedMobileTrackerGroup}/>)
    }

    return (
        <div className="group-mobile-wrapper" style={style1}>
            {content}
        </div>
    )
}