import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import FolderIcon from '@material-ui/icons/Folder';

import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';

// import { CalendarIcon } from "../../../assets/img/ic-calendar-mob.svg";
// import { CalendarNoActiveIcon } from "../../../assets/img/ic-calendar-no-active.svg";
// import { GroupIcon } from "../../../assets/img/ic-group.svg";
// import { GroupNoActiveIcon } from "../../../assets/img/ic-group-no-active.svg";
// import FolderOpenIcon from '@material-ui/icons/FolderOpen';
// import ScheduleIcon from '@material-ui/icons/Schedule';
// import RestoreIcon from '@material-ui/icons/Restore';
// import LocationOnIcon from '@material-ui/icons/LocationOn';

import { useBottomNavigation } from "../../../hooks/useBottomNavigation";

import "./BottomNavigationWrapper.css";
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useGlobal from "../../../store";
import useMobileTrackerGroupSelect from "../../../hooks/useMobileTrackerGroupSelect";
import useMobileGroupNavigation from "../../../hooks/useMobileGroupNavigation";

const useStyles = makeStyles({
    root: {
        width: "100%",
        position: "fixed",
        left: 0,
        bottom: 0,
        zIndex: 10,
        justifyContent: "space-around"
    },
});

export const BottomNavigationWrapper = () => {
    const classes = useStyles();
    const { isMobileSize } = useWindowDimensions();
    const [value, setValue] = useState("");
    const [seletedTab, setSelectedTab] = useBottomNavigation();
    const [infoWindowOpened,setSelectGroup]= useGlobal(
        state=>state.infoWindowOpened,
        actions => actions.trackerGroup.useSelectGroup
    )
    
    const [selectedGroupNavigationTab, setGroupNavigationTab] = useMobileGroupNavigation();

    useEffect(() => {
        setSelectedTab(value);
    }, []);

    if (!isMobileSize)
        return "";

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                let dd = newValue == value ? "" : newValue;
                setValue(dd);
                setSelectedTab(dd);
                setSelectGroup(0,0);
                if(dd=="group"){
                    setGroupNavigationTab("groups");
                }
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction label="Groups" value="group" icon={<FolderIcon />} >

            </BottomNavigationAction>
            <BottomNavigationAction label="Schedule" value="schedule" icon={<DateRangeTwoToneIcon />} />
        </BottomNavigation>
    );
}