import React, { useEffect, useState } from "react";
import { makeStyles, Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@material-ui/core";
import { Input, Select, FormControl, InputLabel, TextField, IconButton } from "@material-ui/core";
import { SketchPicker } from 'react-color';

import useGlobal from "../../store";
import {DeleteGroupConfirmation} from "./DeleteGroupConfirmation"

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        marginBottom: 10
    },
    button: {
        color: "#2160b5",
        border: "1px solid #2160b5",
        "&:focus": {
            outline: "none"
        }
    },
    weekDay: {
        width: 40,
        height: 40,
        margin: 2,
        "&:focus": {
            outline: "none"
        }
    },
    weekDaySelected: {
        backgroundColor: "#2160b5",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#263c58",
            color: "#ffffff",
        }
    }
}));

export const CreateGroup = (props) => {
    const classes = useStyles();
    const [groupTitle, setGroupTitle] = useState("");
    const [groupColor, setGroupColor] = useState("");

    const [trackerGroups, trackerGroupService] = useGlobal(
        state => state.trackerGroups,
        actions => actions.trackerGroups.trackerGroupService
    )

    const [currentTerritoryId, territoryService] = useGlobal(
        state => state.currentTerritoryId,
        actions => actions.territory.territoryService
    )


    const { createTrackerGroup, updateTrackerGroup, deleteTrackerGroup } = trackerGroupService();
    const { getTrackerGroups } = territoryService();

    const [showGroupDeleteConfirmationModal, toggleShowGroupDeleteConfirmationModal] = useState(false);


    const formIsValid = () => {
        return true;
    }

    useEffect(() => {        
        resetFormData();
        if (props.trackerGroupId) {
            const trackerGroup = trackerGroups.data.find(item => item.id == props.trackerGroupId);
            if(trackerGroup){
                setGroupTitle(trackerGroup.name);
                setGroupColor(trackerGroup.color);
            }
        }
    },[props]);

    const onChangeGroupTitle = (event) => {
        setGroupTitle(event.target.value);
    }

    const onChangeGroupColor = (color) => {
        setGroupColor(color.hex);
    }

    const resetFormData = () => {
        setGroupTitle("");
        setGroupColor("");
    }

    const handleOnClose = () => {
        resetFormData();
        props.toggleShowAddNewGroupModal(false);
    }

    const handleOnDelete = ()=>{
        toggleShowGroupDeleteConfirmationModal(true);

    }

    const handleDialogClose = () => {
        handleOnClose();
    }

    const onConfirmGroupDelete = (groupId) => {
        deleteTrackerGroup(groupId)
          .then(r => { 
          handleDialogClose();
        })
          .catch(err => console.log(err));
          toggleShowGroupDeleteConfirmationModal(false);
      }
    
    const handleOnSaveClick = () => {
        let payload = {
            territory: currentTerritoryId,
            name: groupTitle,
            color: groupColor,
            id : props.trackerGroupId,
            description:""
        }
        if(props.trackerGroupId){
            updateTrackerGroup(payload)
            .then(r => {
                //getTrackerGroups();               
                handleDialogClose();
            })
            .catch(e => {
                //getTrackerGroups();
                handleDialogClose();
            });
        }
        else{
            createTrackerGroup(payload)
            .then(r => {
                //getTrackerGroups();
                handleDialogClose();
            })
            .catch(e => {
                //getTrackerGroups();
                handleDialogClose();
            });
        }
    }
    

    return (
        <div>
            <DeleteGroupConfirmation
            pumpScheduleId={props.trackerGroupId}
            show={showGroupDeleteConfirmationModal}
            onConfirmDelete={onConfirmGroupDelete}
            toggleShowDeleteConfirmationModal={toggleShowGroupDeleteConfirmationModal} />
            <Dialog open={props.show} onClose={handleOnClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add new group</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="title"
                                label="Title"
                                variant="outlined"
                                value={groupTitle}
                                onChange={onChangeGroupTitle}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-12">
                            <SketchPicker
                                color={groupColor}
                                onChangeComplete={onChangeGroupColor} />
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={handleOnDelete} variant="outlined" color="primary">Delete</Button>

                    <Button className={classes.button} onClick={handleDialogClose} variant="outlined" color="primary">Cancel</Button>
                    <Button className={classes.button} onClick={handleOnSaveClick} disabled={!formIsValid()} variant="outlined" color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}