import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';


import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useLocations } from '../../hooks/useLocations';

import "./LocationComponent.css";
import useGlobal from '../../store';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const LocationComponent = () => {
    const classes = useStyles();
    const [locationsIsOpened, toggleLocations] = useLocations();

    const [currentTerritoryId] = useGlobal(
        store => store.currentTerritoryId
    );

    const [territories, territoryService] = useGlobal(
        store => store.territories,
        actions => actions.territory.territoryService
    );

    const { getTerritories, setCurrentTerritory } = territoryService();

    const [companies, companyService] = useGlobal(
        store => store.companies,
        actions => actions.companies.companyService
    );

    const [hubConnection] = useGlobal(
        store=>store.hubConnection
    )

    const { loadForCurrentCustomer } = companyService();

    useEffect(() => {
        getTerritories();
        loadForCurrentCustomer();
    }, []);

    return <Paper className="location-wrapper">
        <div>
            <h4>Locations</h4>
            <div className="">
                <List component="nav" aria-label="secondary mailbox folders">
                    {
                        companies.data.map((item) => {
                            return <div key={`company-${item.id}`}>
                                <ListItem >
                                    <ListItemText primary={item.companyName} />
                                </ListItem>
                                <List component="div" disablePadding>
                                    {
                                        item.territories.map((item1) => {
                                            return <ListItem key={`territory-${item1.id}`} className={classes.nested} button selected={currentTerritoryId === item1.id} onClick={() => { setCurrentTerritory(item1).then(() => {  hubConnection.reset(); toggleLocations(false) }); }}>
                                                <ListItemText primary={item1.title} />

                                            </ListItem>
                                        })
                                    }
                                </List>
                            </div>
                        })
                    }

                </List>
            </div>
        </div>
    </Paper>
}