import * as signalR from "@microsoft/signalr";
import useGlobal from "../store/index";
import Utils from "../Utils"
import { createTokenProvider } from "./createTokenProvider";
//import { w3cwebsocket as W3CWebSocket } from "websocket";

export const useSocket = () => {

       const [hubConnection, setHubConnection] = useGlobal(
              store => store.hubConnection,
              actions => actions.hub.useHubConnection
       );

       const [schedules, groupScheduleService] = useGlobal(
              state => state.schedules,
              action => action.groupSchedules.groupScheduleService
       )

       const [pumpSchedules, pumpScheduleService] = useGlobal(
              state => state.pumpSchedules,
              action => action.pumpSchedules.pumpScheduleService
       )

       const [trackerGroups, trackerGroupService] = useGlobal(
              state => state.trackerGroups,
              actions => actions.trackerGroups.trackerGroupService
       )

       const [currentTerritoryId, trackerService] = useGlobal(
              state => state.currentTerritoryId,
              actions => actions.trackers.trackerService
       )

       const [locker1, pumpService] = useGlobal(
              state => state.locker,
              actions => actions.pumps.pumpService
       )

       const [locker2, hubWaitingListFactory] = useGlobal(
              state => state.locker,
              actions => actions.hub.hubWaitingListFactory
       );

       const [networkInfo, networkInfoService] = useGlobal(
              state => state.networkInfo,
              action => action.networkInfo.networkInfoService
       );


       const createConnection = () => {
              const tokenProvider = createTokenProvider();
              const { waitListCleaner } = hubWaitingListFactory();
              const { pushNewScheduleInState, updateScheduleInState, removeScheduleFromState, getGroupSchedulesInCurrentTerritory } = groupScheduleService();
              const { pushNewPumpScheduleInState, updatePumpScheduleInState, removePumpScheduleFromState, getPumpSchedulesInCurrentTerritory } = pumpScheduleService();
              const { pushTrackerGroupToState, updateTrackerGroupToState, removeTrackerGroupFromState } = trackerGroupService();
              const { updateTrackerInState, updateTrackerStateById } = trackerService();
              const { setPumpPressure, setPumpState } = pumpService();
              const { setMessageBrokerState, setServerState } = networkInfoService();

              let socket = new WebSocket(Utils.signalRBaseUrl + "ws/mqtt_bridge/root/", ["access_token", tokenProvider.getToken()]);
              socket.onopen = () => {
                     console.log("socket open");
                     let newSocket = socket;
                     socket.send(JSON.stringify({
                            message: {
                                   action: "getinitialstate",
                                   data: {
                                          currentTerritoryId: currentTerritoryId
                                   }
                            }
                     }));
                     setHubConnection(newSocket);
              };

              socket.onerror = function (error) {
                     console.log(error);
                     console.log('Error Occured: ' + error);
              };

              socket.onmessage = (event) => {
                     event = JSON.parse(event.data);
                     switch (event.message.action) {
                            case "trackerchangestate":                                   
                                   updateTrackerStateById(event.message.data.node_id, event.message.data.state, event.message.data.valve, event.message.data.BAT, event.message.data.S1, event.message.data.previous_state);
                                   break;
                            case "pumppressureupdated":
                                   setPumpPressure(event.message.data);
                                   break;
                            case "groupschedulecreated":
                            case "groupscheduledeleted":
                                   getGroupSchedulesInCurrentTerritory();
                                   break;
                            case "groupscheduleupdated":
                                   updateScheduleInState(event.message.data);
                                   break;
                            case "pumpschedulecreated":
                            case "pumpscheduledeleted":
                                   getPumpSchedulesInCurrentTerritory();
                                   break;
                            case "pumpscheduleupdated":
                                   updatePumpScheduleInState(event.message.data);
                                   break;
                            case "groupcreated":
                                   pushTrackerGroupToState(event.message.data);
                                   break;
                            case "groupupdated":
                                   updateTrackerGroupToState(event.message.data);
                                   break;
                            case "groupdeleted":
                                   removeTrackerGroupFromState(event.message.data.id);
                                   getGroupSchedulesInCurrentTerritory();
                                   break;
                            case "trackerupdated":
                                   updateTrackerInState(event.message.data);
                                   break;
                            case "messagebrokerstate":
                                   setMessageBrokerState(event.message.data.mqttinfoisonline);
                                   break;
                            case "serverstate":
                                   setServerState(event.message.data.localnetworkisonline)
                                   break;
                            case "pumpstateupdated":
                                   setPumpState(event.message.data);
                                   break;
                            default:
                                   break;
                     }
              }

              socket.reset = () => {
                     socket = null;
                     createConnection();
              }

              socket.onclose = (event) => {
                     console.log("socket close", event);
                     socket = null;
                     setTimeout(createConnection, 3000);
              }
       }

       return { hubConnection, createConnection };
}