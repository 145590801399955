import { useState, useEffect } from 'react';

let weatherListeners = [];
let weather = {
    isLoading: true,
    data: {}
};
const setWeather = (newState) => {
    weather = newState;
    weatherListeners.forEach((listener) => {
        listener(weather);
    });
};

export default function useWeather() {
    const newListener = useState()[1];
    useEffect(() => {
        weatherListeners.push(newListener);
        return () => {
            weatherListeners = weatherListeners.filter(listener => listener !== newListener);
        };
    }, []);
    return [weather, setWeather];
}