
export const weatherProvider = () => {
    const _baseUrl = "https://api.openweathermap.org/";
    const plygoneId = "5f54d732714b52f211e10860";
    const appId = "faf7bba2d8f7c3d6c33defd6bf5315c1 ";

    const load = async (url) =>{
        const response = await fetch(url);
        return response.ok ? response.json() : Promise.reject({error: 500});
    }

    const currentWeather = async (position) => {
        const url = `${_baseUrl}data/2.5/onecall?lat=${position.lat}&lon=${position.lng}&exclude=hourly,minutely&appid=${appId}`;
        return await load(url)
            .then((r) => {               
                const weather = r;
                return {
                    //date: Moment.unix(r.dt),
                    isLoading:false,
                    data:weather
                };
            })
            .catch((err) => { 
                console.log(err);
                return {
                    //date: Moment.unix(r.dt),
                    isLoading:false,
                    data:{},
                    hasError:true
                };
            });

    }

    return { currentWeather };
}