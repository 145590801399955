import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RevalconIcon from '../../../assets/img/logo-icon.png'
import HomeIcon from '../../../assets/img/ic-home.png'
import HistoryIcon from '../../../assets/img/ic-history.png'
import HistoryDarkIcon from '../../../assets/img/ic-history-dark.svg'
import CalendarIcon from '../../../assets/img/ic-calendar.png'
import CalendarDarkIcon from '../../../assets/img/ic-calendar-dark.svg'

import StopIcon from '../../../assets/img/il-stop-active.png'
import HelpIcon from '../../../assets/img/ic-help.png'
import HelpSvgIcon from '../../../assets/img/ic-help-dark.svg'
import NotficationIcon from '../../../assets/img/ic-notification.png'
import NotficationDarkcon from '../../../assets/img/ic-notification-dark.svg'
import LocationIcon from '../../../assets/img/ic-location.svg'
import LocationDarkIcon from '../../../assets/img/ic-location-dark.svg'

import { createAuthProvider } from '../../../providers/authProvider';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useLocations } from '../../../hooks/useLocations';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';

import { StopAllConfirmation } from "../../StopAll/StopAllConfirmation";


import { LocationComponent } from '../../Locations/LocationComponent';

import "./SideBar.css";
import useGlobal from '../../../store';

export const SideBarComponent = () => {

    const history = useHistory();
    const { width, height, isMobileSize } = useWindowDimensions();
    const [locationsIsOpened, toggleLocations] = useLocations();
    const [showStopAllModal, setShowStopAllModal] = useState(false);
    const drawerWidth = !isMobileSize ? 60 : 240;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },

        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            overflow: "hidden"
        },
        drawerPaper: {
            backgroundColor: "#2160b5",//isMobileSize? "#FFFFFF" : "#2160b5",
            color: "#FFFFFF",//isMobileSize? "#263c58" : "#FFFFFF",
            width: drawerWidth,
            overflow: "hidden"
        },

        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        bottomPush: {
            position: "fixed",
            bottom: 0,
            width: drawerWidth,
            paddingBottom: 10,
            overflow: "hidden"
        },
        divider: {
            backgroundColor: "#FFFFFF",
            opacity: 0.2,
            width: drawerWidth - 20,
            margin: "0 auto"
        },
        active: {
            backgroundColor: "#1b5198"
        }
    }));

    const { logout, isLoggedIn } = createAuthProvider();

    const [activeMenu, setActiveMenu] = useState("home");
    const classes = useStyles();
    const [menuIsOpen, toggleMenu] = useGlobal(
        state => state.menuIsOpen,
        actions => actions.navigation.useToggleMenu
    );


    const [terriroty, stopAllService] = useGlobal(
        state => state.terriroty,
        actions => actions.stopAll.stopAllService
    )

    const { stopAll } = stopAllService();

    const onlogout = (event) => {
        event.preventDefault();
        logout();
        history.push("/login");
    }

    const toggleShowStopAllConfirmationModal = () => {
        setShowStopAllModal(!showStopAllModal);
    }

    if (!isLoggedIn()) {
        return "";
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                className={classes.drawer}
                variant={isMobileSize ? "temporary" : "permanent"}
                classes={{
                    paper: classes.drawerPaper,
                }}
                open={menuIsOpen}
                anchor="left"
                onClose={() => { toggleMenu(false) }}
                onOpen={() => { toggleMenu(true) }}
            >
                <div className={"logo-block"}>
                    <img src={RevalconIcon} className={"logo-icon"} />
                </div>
                <StopAllConfirmation showConfirmationModal={showStopAllModal} onConfirmStopAll={() => { stopAll(terriroty.id) }} toggleShowStopAllConfirmationModal={toggleShowStopAllConfirmationModal} />
                <List>
                    <ListItem button key={"home"} component={Link} to="/" className={`${activeMenu === "home" ? classes.active : ""}`} onClick={() => { setActiveMenu("home"); toggleMenu(false); toggleLocations(false); }}>
                        <ListItemIcon>
                            <img src={HomeIcon} />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItem>
                    <ListItem button key={"history"} component={Link} to="/history" className={`${activeMenu === "history" ? classes.active : ""}`} onClick={() => { setActiveMenu("history"); toggleLocations(false); }}>
                        <ListItemIcon>
                            <img src={HistoryIcon} />
                        </ListItemIcon>
                        <ListItemText primary={"History"} />
                    </ListItem>
                    <ListItem button key={"calendar"} component={Link} to="/calendar" className={activeMenu === "calendar" ? classes.active : ""} onClick={() => { setActiveMenu("calendar"); toggleLocations(false); }}>

                        <ListItemIcon>
                            <img src={CalendarIcon} />
                        </ListItemIcon>
                        <ListItemText primary={"Calendar"} />
                    </ListItem>
                </List>
                <div className={classes.bottomPush}>
                    <List>
                        <ListItem button key={"stopall"} className={activeMenu === "stopall" ? classes.active : ""} onClick={() => { toggleShowStopAllConfirmationModal(true); }} >
                            <ListItemIcon>
                                <img src={StopIcon} />
                            </ListItemIcon>
                            <ListItemText primary={"Stop All"} />
                        </ListItem>
                    </List>
                    <Divider className={classes.divider} />

                    <List >
                        {/* <ListItem button key={"help"} component={Link} to="/help" className={activeMenu == "help" ? classes.active : ""} onClick={() => { setActiveMenu("help");toggleLocations(false); }}>
                            <ListItemIcon>
                                <img src={HelpIcon} />
                            </ListItemIcon>
                            <ListItemText primary={"Help"} />
                        </ListItem>
                        <ListItem button key={"notfications"} component={Link} to="/notfications" className={activeMenu == "notfications" ? classes.active : ""} onClick={() => { setActiveMenu("notfications");toggleLocations(false); }}>
                            <ListItemIcon>
                                <img src={NotficationIcon} />
                            </ListItemIcon>
                            <ListItemText primary={"Notfications"} />
                        </ListItem> */}
                        <ListItem button key={"location"} className={activeMenu === "location" ? classes.active : ""} onClick={() => { toggleLocations(!locationsIsOpened); }}>
                            <ListItemIcon>
                                <img src={LocationIcon} />
                            </ListItemIcon>
                            <ListItemText primary={"Location"} />
                        </ListItem>
                        <ListItem button key={"account"} component={Link} to="/account" className={activeMenu === "account" ? classes.active : ""} onClick={() => { setActiveMenu("account"); toggleLocations(false); }}>
                            <ListItemIcon>
                                <AccountCircleRoundedIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Account"} />
                        </ListItem>
                        <ListItem button key={"logout"} onClick={onlogout}>
                            <ListItemIcon>
                                <ExitToAppRoundedIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Sign out"} />
                        </ListItem>
                    </List>
                    {locationsIsOpened ? <LocationComponent /> : ""}
                </div>
            </SwipeableDrawer>
        </React.Fragment>
    )
}
