import React, { Component } from 'react';
import IcArrowRight from '../../assets/img/ic-arrow-right.svg';
import LiCloudMob from '../../assets/img/il-cloud.svg';
import LiPumpMob from '../../assets/img/il-pump.svg';
import { IconButton } from "@material-ui/core";
import "./NavigationArrow.css";

export default class NavigationArrow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={`navigationArrow ${this.props.direction == "right" ? "right" : "left"}`}  style={{ display: this.props.isVisible ? "block" : "none" }} >
        <IconButton onClick={() => { this.props.slide() }}>
          <img src={IcArrowRight} className={`ic-arrow-right ${this.props.direction == "right" ? "vertical_horizontal_center" : ""}`} />
        </IconButton>
      </div>
    );
  }
}
