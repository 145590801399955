import React, { useEffect } from 'react'
import LiColud from '../../assets/img/il-cloud.svg'
import LiHumidity from '../../assets/img/il-humidity.png'
import LiWaterneds from '../../assets/img/il-waterneeds.png'
import useWeather from "../../hooks/useWeather";
import { weatherProvider } from "../../providers/weatherProvider";
import Moment from "moment";
import useGlobal from "../../store/index";
import "./WeatherComponent.css"

export const WeatherComponent = (props) => {
    const [weather, setWeather] = useWeather();
    const [mapCenterPosition] = useGlobal(
        state => state.mapCenterPosition
    )
    const { currentWeather } = weatherProvider();

    useEffect(() => {
        if (mapCenterPosition) {

            currentWeather({
                lat: mapCenterPosition.lat,
                lng: mapCenterPosition.lng
            }).then(r => {
                setWeather(r);
            });
        }
    }, [mapCenterPosition]);
    
    if (!weather ||weather.isLoading) {
        return "";
    }

    return (
        weather.hasError?
            <div  className="col-lg-3 card wather_block center-content">
                <h3>Weather is not avalible</h3>
            </div>
        :
        <div className="col-lg-3 card wather_block">
            <div className="wather_top">
                <div className="wather_top_left">
                    <img src={LiColud} className="il-cloud" />
                    <span>Now</span>
                </div>
                <div className="wather_top_right text-right">
                    <p>{(weather.data.current.temp - 273.15).toFixed(1)}°C / {weather.data.current.humidity}%</p>
                </div>
            </div>
            <div className="wather_content">
                <div className="wather_content_1">
                    <div className="wather_content_1_left">
                        <img src={LiHumidity} className="il-humidity" />
                        <span>Humidity (% RH)</span>
                    </div>
                    <div className="wather_content_1_right text-right">
                        <p>{weather.data.current.humidity}%</p>
                    </div>
                </div>
                <div className="wather_content_2">
                    <div className="wather_content_2_left">
                        <img src={LiWaterneds} className="il-waterneeds" />
                        <span>Water Needs (1/m3)</span>
                    </div>
                    <div className="wather_content_2_right text-right">
                        <p>6.7 L</p>
                    </div>
                </div>
            </div>
            <div className="wather_footer">
                {weather.data.daily.slice(1, 4).map((item, i) => {
                    return (<div key={i}>
                        <p>{Moment.unix(item.dt).format("dddd")}</p>
                        <span>{(item.temp.day - 273.15).toFixed(1)}°  <span>{item.humidity}%</span></span>
                    </div>);
                })}
            </div>
        </div>
    )
}