import React from "react"

import "./ScheduleType.css";

export const ScheduleType = (props) => {
    let type = "";
    if (props.type === 0) {
        type = "Node";
    }
    else {
        type = "Pump";
    }
    return <span className={`typeContainer type${type}`}>{type}</span>;
}