import React, { useState, useEffect } from "react";
import { makeStyles, Fab, Modal } from "@material-ui/core";
import useGlobal from '../../store';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import LiCloudMob from '../../assets/img/il-cloud.svg';
import LiPumpMob from '../../assets/img/il-pump.svg';

import { WeatherComponent } from "../Weather/WeatherComponent";
import { PumpsList } from "./PumpsList";
import "./SideFloatingButtons.css";

export const SideFloatingButtons = () => {


    const { width, isMobileSize } = useWindowDimensions();

    const useStyles = makeStyles((theme) => ({
        fab: {
            backgroundColor: "#FFFFFF",

            "&:focus": {
                outline: "none"
            }
        },
        paper: {
            position: 'absolute',
            minWidth: width <= 280 ? width - 20 : 310,
            maxWidth:350,
            backgroundColor: theme.palette.background.paper,
            borderRadius: "0.25rem",
            "&:focus": {
                outline: "none"
            }
        },
    }));

    const classes = useStyles();
    const [pumpsModalIsOpen, togglePupmsModal] = useState(false);
    const [weatherModalIsOpen, toggleWeatherModal] = useState(false);
    const [anyPumpIsOn, setAnyPumpIsOn] = useState(false);
    const [pumps] = useGlobal(
        state => state.pumps
      )

      useEffect(()=>{   
          if(pumps && pumps.data !== undefined && pumps.data.length>0){
              setAnyPumpIsOn( pumps.data.some(item=>item.state));
          }
        },[pumps]);
        
    const getModalStyle = () => {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    if (!isMobileSize)
        return "";
    return (
        <div className={`side-floating-buttons`}>
            <Modal
                open={weatherModalIsOpen}
                onClose={() => { toggleWeatherModal(false) }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <WeatherComponent />
                    {/* <h2 id="simple-modal-title">Text in a modal</h2>
                    <p id="simple-modal-description">
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </p> */}
                </div>
            </Modal>
            <Modal
                open={pumpsModalIsOpen}
                onClose={() => { togglePupmsModal(false) }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <PumpsList/>
                    {/* <h2 id="simple-modal-title">Text in a modal</h2>
                    <p id="simple-modal-description">
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </p> */}
                </div>
            </Modal>
            <Fab className={classes.fab} color="default" onClick={() => { toggleWeatherModal(true) }}>
                <img src={LiCloudMob} />
            </Fab>

            <Fab className={classes.fab} color="default" onClick={() => { togglePupmsModal(true) }}>
                {anyPumpIsOn && <span className="pump-indicator"></span>}
                <img src={LiPumpMob} />
            </Fab>
        </div>
    );
}