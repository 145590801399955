import React, { useEffect, useState, useRef } from 'react';
import { PumpComponent } from '../Pumps/PumpComponent';
import Slider from "react-slick";
import NavigationArrow from '../Common/NavigationArrow';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { CircularProgress } from '../Common/CircularProgress';
import useGlobal from "../../store";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import './PumpsList.css'

const useStyles = makeStyles((theme) => ({
    button: {
        color: "#2160b5",
        border: "1px solid #2160b5",
    },
}));

export const PumpsList = (props) => {
    const [showLeftArrow, setShowLeftArrow] = useState(true);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const refContainer = useRef(null);

    const [pumps, pumpService] = useGlobal(
        state => state.pumps,
        action => action.pumps.pumpService
    );
    const [currentTerritoryId] = useGlobal(
        store => store.currentTerritoryId
      );
    const { getInCurrentTerritory  } = pumpService();
    useEffect(() => {
        getInCurrentTerritory();
    }, [currentTerritoryId])

    const next = () => {
        refContainer.current.slickNext();
    }

    const previous = () => {
        refContainer.current.slickPrev();
    }


    let slidToShowCount = 3;
    if (pumps && pumps.data.length <= 2)
        slidToShowCount = pumps.data.length;

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        //centerMode: false,
        slidesToScroll: 1,
    };

    let pumpSlider = "";
    if (pumps.data.length > 0) {
        pumpSlider = (
            <div className="pump_content row">
                <div className="carusel_block">
                    <Slider ref={refContainer} {...settings}>
                        {pumps.data.map((p, i) => <PumpComponent key={i} turnedOn={p.state} id={p.id} title={p.title} pressure={p.pressure} />)}
                    </Slider>
                </div>
                <NavigationArrow slide={previous} isVisible={showLeftArrow} direction={"left"} /> 
                <NavigationArrow slide={next} isVisible={showRightArrow} direction={"right"} /> 
            </div>
        );
    }
    else {
        pumpSlider = (
            <div className="row justify-content-center">
                <div className="col align-self-center">
                    <span>No Data</span>
                </div>
            </div>
        );
    }

    return (
        <div className={"col-lg-3 card pump_block"} >
            <div className="pumps_top">
                <div className="float-left leftSide">
                    <span>Pumps</span>
                </div>            
            </div>
            {pumps.isLoading ? <CircularProgress /> : pumpSlider}
        </div>
    )

}
