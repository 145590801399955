import React, { useState, useEffect } from "react";
import { makeStyles, Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@material-ui/core";
import { Input, Select, FormControl, InputLabel, TextField, IconButton } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import useGlobal from "../../store";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        marginBottom: 10
    },
    button: {
        color: "#2160b5",
        border: "1px solid #2160b5",
        "&:focus": {
            outline: "none"
        }
    },
    weekDay: {
        width: 40,
        height: 40,
        margin: 2,
        "&:focus": {
            outline: "none"
        }
    },
    weekDaySelected: {
        backgroundColor: "#2160b5",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#263c58",
            color: "#ffffff",
        }
    }
}));

export const CreatePumpSchedule = (props) => {
    const classes = useStyles();

    const [pumpSchheduleTitle, setPumpScheduleTitle] = useState("");
    const [selectedPumpId, setSelectedPumpId] = useState(undefined);
    const [startDateTime, setStartDateTime] = useState(undefined);
    const [endDateTime, setEndDateTime] = useState(undefined);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [locker, setLocker] = useState(0);
    const [pumps, pumpService] = useGlobal(
        state => state.pumps,
        actions => actions.pumps.pumpService
    );
    const [pumpSchedules, pumpScheduleService] = useGlobal(
        state => state.pumpSchedules,
        actions => actions.pumpSchedules.pumpScheduleService
    );

    const [schedules, groupScheduleService] = useGlobal(
        state => state.schedules,
        action => action.groupSchedules.groupScheduleService
    )
    const { getGroupSchedulesInCurrentTerritory } = groupScheduleService();
    const { createPumpSchedule,updatePumpSchedule, getPumpSchedulesInCurrentTerritory } = pumpScheduleService();

    useEffect(() => {
        let initDate = new Date();
        setStartDateTime(initDate);
        setEndDateTime(initDate);
    }, []);

    useEffect(() => {
        if (Array.isArray(pumps.data) && pumps.data.length > 0) {
            setSelectedPumpId(pumps.data[0].id)
        }
    }, [pumps])

    useEffect(() => {
        if (props.schedule) {
            console.log(props.schedule);
            setPumpScheduleTitle(props.schedule.title);
            setSelectedPumpId(props.schedule.pumps[0].id);
            setStartDateTime(props.schedule.startDateTime);
            setEndDateTime(props.schedule.endDateTime);
            setSelectedWeekDays(props.schedule.days);
        }
    }, [props.schedule]);


    const handleOnClose = () => {
        resetFormData();
        props.toggleShowAddNewPumpScheduleModal(false);
    }

    const formIsValid = () => {
        return true;
    }

    const handleDialogClose = () => {
        handleOnClose();
    }


    const resetFormData = () => {
        setPumpScheduleTitle("");
        setSelectedPumpId(undefined);
        setStartDateTime(undefined);
        setEndDateTime(undefined);
        setSelectedWeekDays([]);
    }

    const handleOnSaveClick = () => {
        let payload = {
            title: pumpSchheduleTitle,
            start_date: Moment(startDateTime).format("YYYY-MM-DD"),
            start_time: Moment(startDateTime).format("HH:mm:ss"),
            end_date: Moment(endDateTime).format("YYYY-MM-DD"),
            end_time: Moment(endDateTime).format("HH:mm:ss"),
            pump_id: parseInt(selectedPumpId),
            days: selectedWeekDays
        }

        if (props.schedule) {
            payload.id = props.scheduleId
            payload.pumps=[payload.pump_id];
            delete payload.pump_id;
            updatePumpSchedule(payload).then(() => {
                alert("Updated");
            }).catch((err) => {
                console.log(err);
                alert("Someting went wrong(");
            })
            .finally(() => {
                props.toggleShowAddNewPumpScheduleModal(false);
            });
        }
        else {
            createPumpSchedule(payload).then(() => {
                //alert("Added");
            })
                .catch((err) => {
                    console.log(err);
                    alert("Someting went wrong(");
                })
                .finally(() => {
                    // getGroupSchedulesInCurrentTerritory()
                    // getPumpSchedulesInCurrentTerritory();
                    props.toggleShowAddNewPumpScheduleModal(false);
                });;
        }
    }


    const handleOnPumpSelectChange = (event) => {
        setSelectedPumpId(event.target.value);
    }

    const onPumpScheduleTitleChange = (event) => {
        setPumpScheduleTitle(event.target.value);
    }

    const handleStartDateTime = (date) => {
        setStartDateTime(date)
    }

    const handleEndDateTime = (date) => {
        setEndDateTime(date);
    }

    const handleWeekDayClick = (weekDay) => {
        let tmpList = selectedWeekDays;
        if (selectedWeekDays.some(item => item == weekDay)) {
            const index = tmpList.indexOf(weekDay);

            if (index > -1) {
                tmpList.splice(index, 1);
            }
        }
        else {
            tmpList.push(weekDay);
        }
        setSelectedWeekDays(tmpList);
        setLocker(locker + 1);
    }

    if (!Array.isArray(pumps.data)) {
        return "";
    }

    return (
        <Dialog open={props.show} onClose={handleOnClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add new pump schedule</DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-dialog-native">Pump</InputLabel>
                    <Select
                        native
                        value={selectedPumpId}
                        onChange={handleOnPumpSelectChange}
                    >
                        {pumps.data.map((item, i) => {
                            return <option key={i} value={item.id}>{item.title}</option>
                        })}
                    </Select>
                </FormControl>
                <div className="row">
                    <div className="col-md-12">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Title"
                            variant="outlined"
                            value={pumpSchheduleTitle}
                            onChange={onPumpScheduleTitleChange}
                            fullWidth
                        />
                    </div>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="row">
                        <div className="col-md-6">
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                format="MM/dd/yyyy HH:mm"
                                margin="normal"
                                id="start-date"
                                label="Start date"
                                value={startDateTime}
                                onChange={handleStartDateTime}
                                autoOk
                            />
                        </div>
                        <div className="col-md-6">
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                format="MM/dd/yyyy HH:mm"
                                margin="normal"
                                id="end-date"
                                label="End date"
                                value={endDateTime}
                                onChange={handleEndDateTime}
                                autoOk
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-2">
                            <div className="row justify-content-center align-items-center">
                                <IconButton onClick={() => { handleWeekDayClick(1); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 1) ? classes.weekDaySelected : ""}`}>M</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(2); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 2) ? classes.weekDaySelected : ""}`}>T</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(3); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 3) ? classes.weekDaySelected : ""}`}>W</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(4); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 4) ? classes.weekDaySelected : ""}`}>T</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(5); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 5) ? classes.weekDaySelected : ""}`}>F</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(6); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 6) ? classes.weekDaySelected : ""}`}>S</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(0); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 0) ? classes.weekDaySelected : ""}`}>S</IconButton>
                            </div>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={handleDialogClose} variant="outlined" color="primary">Cancel</Button>
                <Button className={classes.button} onClick={handleOnSaveClick} disabled={!formIsValid()} variant="outlined" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
}