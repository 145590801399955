import React, { useState } from 'react';
import Api from "../../lib/Api";
import Logo from "../../assets/img/logo-full.png";
import { createAuthProvider } from '../../providers/authProvider';
import { Redirect, useHistory } from 'react-router-dom';
import {apiProxy} from '../../hooks/apiProxy';
import {CircularProgress} from "../Common/CircularProgress";
import "./LoginComponent.css";

const LoginComponent = () => {
    const [credentials, setCredentials] = useState({
        email: 'garniuser@test.com',
        password: 'Aa123456@test'
    });
    const [isLoading, setIsLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState(true);
    const [authenticationResult, setAuthenticationResult] = useState(undefined);
    const {post} = apiProxy();
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
        setFormIsValid(credentials.email !== undefined && credentials.password != undefined);
    };
    
    const { login } = createAuthProvider();
    const history = useHistory();
    const  onSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        
        if (formIsValid) {
            setIsLoading(true);
            const payload = {
                username: credentials.email,
                password: credentials.password
            };
            try {
                let loginResult = await post("auth/", payload);
                if (loginResult.status == 200) {                  
                    login(loginResult.data);
                    setIsLoading(false);
                    history.push("/");
                }
                else {
                    setIsLoading(false);
                    setAuthenticationResult(false);
                }
            } catch (error) {
                setIsLoading(false);
                setAuthenticationResult(false);
            }                       
        }
    };

    return (
        <div className={"Sign-in-block"}>
            <div className={"logo-full-block"}>
                <img src={Logo}
                    className={"logo-full"} />
            </div>
            <h4>Sign In</h4>
            <form className={"Sign-in-form"}>
                <label htmlFor={"email"}>Email</label>
                <input type={"email"} name={"email"} id={"email"} value={credentials.email} onChange={onChange} placeholder={"Email"} />

                <label htmlFor={"password"}>Password</label>
                <input type={"password"} name={"password"} id={"password"} onChange={onChange} value={credentials.password} placeholder={"Password"} />
                {(authenticationResult !== undefined && !authenticationResult) ? <span>Incorrect login</span> : ""}
                <button type={"submit"} id={"sign_butto1n"} style={{height:56}} className={`btn-submit ${formIsValid ? "Sign_submit_active" : ""}`} onClick={onSubmit} >
                    {isLoading?<CircularProgress color="#ffffff" height="25"/> :"Sign In"}
                </button>
            </form>
        </div>
    );
}

export default LoginComponent;
