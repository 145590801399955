import React, { useState } from 'react';
import Water2 from '../../assets/img/water-2.svg';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import EditIcon from '@material-ui/icons/Edit';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { CircularProgress } from "../Common/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { useTrackerServer } from "../../hooks/useTrackerServer";
import useGlobal from "../../store/index";

import './GroupComponent.css'

export const GroupComponent = (props) => {
    const useStyles = makeStyles(() => ({
        groupBorderColor: {
            borderColor: `${props.color}90`
        },
        hoverBgColor: {
            "&:hover": {
                backgroundColor: `${props.color}35`
            }
        },
        iconBgColor: {
            backgroundColor: props.color,
            "&:after": {
                backgroundColor: props.color
            }
        },
        folderColor: {
            color: props.color,
            fontSize: 20,
            marginLeft: 13,
            marginRight: 7.5
        },
        selected: {
            backgroundColor: `${props.color}35`
        },
        turnOnOffButton: {
            padding: 1,
            "&:hover": {
                backgroundColor: `${props.color}90`
            }
        }
    }));
    // const [trackerServer, setTrackerServer] = useState({});
    // const { loadTrackerServer } = useTrackerServer();

    const [selectedTrackerGroupId, setSelectGroup] = useGlobal(
        state => state.selectedTrackerGroupId,
        actions => actions.trackerGroup.useSelectGroup
    );

    const [selectedTrackerId, trackerService] = useGlobal(
        state => state.selectedTrackerId,
        actions => actions.trackers.trackerService
    )

    const { setSelectTrackerV2 } = trackerService();

    // const [terriroty] = useGlobal(
    //     state => state.terriroty
    // );

    const [treackersGlobal] = useGlobal(
        state => state.trackers
    );

    // const [hubConnection] = useGlobal(
    //     state => state.hubConnection
    // );

    const [hubWaitingList, hubWaitingListFactory] = useGlobal(
        state => state.hubWaitingList,
        actions => actions.hub.hubWaitingListFactory
    );

    const collapsed = selectedTrackerGroupId == props.trackerGroupId;

    const trackers = treackersGlobal.data.filter((item) => item.group.id == props.trackerGroupId);// ? collapsedTrackers.data.filter((item) => item.group.id == props.trackerGroupId) : [];

    const onTrackerItemClick = (trackerId, tracker) => {
        setSelectGroup(selectedTrackerGroupId, trackerId);
        setSelectTrackerV2(tracker);
    }

    const startStopBlockClickHandle = (tracker) => {
        const action = tracker.state == 1 ? "2" : "1";
        const { add, invokeSendMessage } = hubWaitingListFactory();
        add(tracker);
        invokeSendMessage(tracker, action);
    }

    const classes = useStyles();
    const onEditClick = (event) => {
        props.onEdit(event, props.trackerGroupId)
    }

    return (
        <div className={`groups ${classes.groupBorderColor}`}>
            <div className={`groups_head ${classes.hoverBgColor}`} onClick={() => { collapsed ? setSelectGroup(0) : setSelectGroup(props.trackerGroupId); }}>
                <div className="icon_name">
                    {collapsed ? <FolderOpenIcon className={classes.folderColor} /> : <FolderIcon className={classes.folderColor} />}

                    {/* <img src={IconGreen} className="icon" style={{display:"none"}}/> */}
                    <span className="icon_back" style={{ display: "none" }}></span>

                    <span className="name">
                        <p>{props.name}</p>
                    </span>
                </div>
                <div className={`float-right`}>
                    {props.is_editable ? <IconButton size="small" className={`${classes.turnOnOffButton}`} onClick={onEditClick}>
                        <EditIcon />
                    </IconButton> : ""}

                    <div className={`all_wather float-right ${trackers.length > 0 && trackers.every((item) => item.state === 1) ? "on" : "off"}`}>
                        <img src={Water2} className="water-2" />
                    </div>
                </div>
            </div>
            <div className="group_list" style={{ display: collapsed ? "block" : "none" }}>

                {
                    !treackersGlobal.isLoading ?
                        trackers.map((item, i) => {
                            return (
                                <div key={i} className={`group ${classes.hoverBgColor} ${selectedTrackerId === item.id ? classes.selected : ""}`} onClick={() => { onTrackerItemClick(item.id, item) }}>
                                    <div className="icon_name">
                                        <span className={`icon ${classes.iconBgColor}`}></span>
                                        <span className="name">{item.title}</span>
                                    </div>
                                    <div className={`all_wather`}>
                                        {
                                            item.isWaiting ? <CircularProgress color={props.color} height={20} width={20} /> :

                                                <IconButton className={`${classes.turnOnOffButton} ${item.state === 1 ? "on" : "off"}`} onClick={() => { startStopBlockClickHandle(item) }}>
                                                    <img src={Water2} className="water-2" />
                                                </IconButton>
                                        }
                                    </div>
                                </div>
                            )
                        }) : <CircularProgress color={props.color} height={20} width={20} />}
            </div>
        </div>
    )
}