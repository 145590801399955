import { apiProxy } from "../hooks/apiProxy";

export const stopAllService = (store) => {
      const stopAll = async (territoryId) => {
        const { post } = apiProxy();
        try {        
              await post(`groupschedules/stopall/`, { territoryId:territoryId });
        } catch (error) {      
            console.log(error);
        }
    }
    return { stopAll }
}