import React, { useState } from "react";
import { CircularProgress } from "../Common/CircularProgress";
import { GroupComponent } from '../Group/GroupComponent';
import useGlobal from "../../store/index";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CreateGroup } from "../Group/CreateGroup";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { trackerGroup } from "../../actions";


const useStyles = makeStyles((theme) => ({
    button: {
        color: "#2160b5",
        border: "1px solid #2160b5",
    },
}));

export const MainBlockGroupList = () => {
    const classes = useStyles();
    const [showAddNewGroupModal, toggleShowAddNewGroupModal] = useState(false);
    const [selectedTrackerGroupId, setSelectedTrackerGroupId] = useState(undefined);
    const [trackerGroupData, getTrackerGroups] = useGlobal(
        state => state.trackerGroups,
        actions => actions.territory.useGetTrackerGroups
    );

    const trackerGroups = {
        groups: trackerGroupData.data,
        isLoading: trackerGroupData.isLoading
    }

    const onGroupEdit = (event, trackerGroupId) => {
        event.preventDefault();
        setSelectedTrackerGroupId(trackerGroupId);
        toggleShowAddNewGroupModal(true);
    }

    const onGroupDelete = (trackerGroupId) => {

    }

    const windowDimensions = useWindowDimensions();
    if (windowDimensions && windowDimensions.isMobileSize)
        return "";
        
    let groupsList = "";
    if (trackerGroups.groups && trackerGroups.groups.length > 0) {
        groupsList = trackerGroups.groups.map((item, i) => {
            return (<GroupComponent
                key={i}
                collapsed={false}
                trackerGroupId={item.id}
                name={item.name}
                description={item.description}
                is_editable={item.is_editable}
                color={item.color}
                onEdit={onGroupEdit}
                onDelete={onGroupDelete}
            />)
        });
    }
    else {
        groupsList = (
            <div className="row justify-content-center">
                <div className="col align-self-center">
                    <span>No Data</span>
                </div>
            </div>
        )
    }

    const onAddNewClick = () => {
        setSelectedTrackerGroupId(undefined);
        toggleShowAddNewGroupModal(true);
    }

    return (
        <div>
            {trackerGroups.isLoading ? <CircularProgress /> : groupsList}
            <div className={'float-right'} style={{ marginTop: 10 }}>
                <CreateGroup show={showAddNewGroupModal} toggleShowAddNewGroupModal={toggleShowAddNewGroupModal} trackerGroupId={selectedTrackerGroupId} />
                <Button variant="outlined" className={classes.button} startIcon={<AddIcon />} size="small" color="primary" onClick={onAddNewClick}>
                    Add Group
                </Button>
            </div>
        </div>
    );
}