import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../actions";

const initialState = {
  locker: 0,
  menuIsOpen: false,
  currentTerritoryId: 1,
  companies: {
    isLoading: true,
    data: []
  },
  terriroty: {
    actionKey: "",
    actionTopic: "",
    companyId: 0,
    id: 0,
    order: 0,
    title: ""
  },
  trackerServers: {
    isLoading: true,
    data: []
  },
  territories: {
    isLoading: true,
    data: []
  },
  selectedTrackerGroupId: 0,
  hubWaitingList: [],
  selectedTrackerId: 0,
  mapCenterPosition: { lat: 40.08925, lng: 44.6498 },
  infoWindowOpened: false,
  infoWindowPostion: { lat: 40.14, lng: 44.5 },
  selectedTracker: undefined,
  hubConnection: null,
  collapsedTrackers: {
    isLoading: true,
    data: []
  },
  networkInfo: {
    mqqttInfoIsOnline: false,
    localNetworkIsOnline: false
  },
  trackerGroups: {
    isLoading: true,
    data: []
  },
  pumps: {
    data: [],
    isLoading: true
  },
  pumpSchedules: {
    data: [],
    isLoading: true
  },
  schedules: {
    data: [],
    isLoading: true
  },
  trackerSchedules: {
    data: [],
    isLoading: true
  },
  trackers: {
    isLoading: true,
    data: []
  },
  trackersForMapIsLoading: true,
  trackersForMap: {
    isLoading: true,
    data: []
  },
  history: {
    isLoading: true,
    totalCount: 0,
    currentPage: 0,
    itemsInPerPage: 10,
    data: []
  },
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
