import React from "react";
import { makeStyles, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  button: {
    color: "#2160b5",
    border: "1px solid #2160b5",
    "&:focus": {
      outline: "none"
    }
  },
}));

export const DeleteConfirmation = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.toggleShowDeleteConfirmationModal(false)
  };

  const handleConfirm = () => {
    if (props.scheduleId) {
      props.onConfirmDelete(props.scheduleId);
    }
  }

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Schedule delete confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure want delete schedule?
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose} color="primary">Cancle</Button>
        <Button className={classes.button} onClick={handleConfirm} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}