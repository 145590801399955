import { apiProxy } from '../hooks/apiProxy';

export const trackerScheduleService = (store) => {
    const { get, patch, post, remove } = apiProxy();

    const getTrackerSchedule = async (scheduleId) => {

        try {
            var response = await get(`trackers/${scheduleId}/schedules`);
        } catch (error) {

        }
    }

    const getTrackerSchedulesInCurrentTerritory = async () => {
        const trackerSchedules = {
            data: [],
            isLoading: true
        };

        store.setState({ trackerSchedules });

        try {
            let response = await get(`territories/${store.state.currentTerritoryId}/trackerschedules`);
                 
            const trackerSchedules = {
                data: response.filter(x => !x.is_expired),
                isLoading: false
            };

            store.setState({ trackerSchedules });
        } catch (error) {
            console.log(error);
            const trackerSchedules = {
                data: [],
                isLoading: false
            };

            store.setState({ trackerSchedules });
        }
    }

    const createTrackerSchedule = async (payload) => {
        try {
            return await post(`trackerschedules/`, payload);
        } catch (error) {
            throw error;
        }
    }

    const updateTrackerSchedule = async (payload) => {
        try {
            return await patch(`trackerschedules/`, payload);
        } catch (error) {
            throw error;
        }
    }

    const deleteTrackerSchedule = async (trackerScheduleId) => {
        try {
            return await remove(`trackerschedules/${trackerScheduleId}/`);
        } catch (error) {
            throw error;
        }
    }

    const pushNewTrackerScheduleInState = (schedule) => {
        const newSchedules = { ...store.state.trackerSchedules };

        newSchedules.data.push(schedule);

        store.setState({ trackerSchedules: newSchedules });
    }

    const updateTrackerScheduleInState = (schedule) => {
        const newSchedules = { ...store.state.trackerSchedules };

        for (let i = 0; i < newSchedules.data.length; i++) {
            if (newSchedules.data[i].id == schedule.id) {
                newSchedules.data[i].is_enabled = schedule.is_enabled;
            }
        }

        store.setState({ trackerSchedules: newSchedules });
    }

    const removeTrackerScheduleFromState = (scheduleId) => {
        const newSchedules = { ...store.state.trackerSchedules };

        newSchedules.data = newSchedules.data.filter((e) => e.id != scheduleId);

        store.setState({ trackerSchedules: newSchedules });
    }

    return {
        getTrackerSchedulesInCurrentTerritory,
        createTrackerSchedule,
        updateTrackerSchedule,
        deleteTrackerSchedule,
        pushNewTrackerScheduleInState,
        updateTrackerScheduleInState,
        removeTrackerScheduleFromState
    };
}