import { useState, useEffect } from 'react';

let mobileNavigationListeners = [];
let selectedGroupNavigationTab = false;
const setGroupNavigationTab = (newState) => {
    selectedGroupNavigationTab = newState;
    mobileNavigationListeners.forEach((listener) => {
        listener(selectedGroupNavigationTab);
    });
};

export default function useMobileGroupNavigation() {
    const newListener = useState()[1];
    useEffect(() => {
        mobileNavigationListeners.push(newListener);
        return () => {
            mobileNavigationListeners = mobileNavigationListeners.filter(listener => listener !== newListener);
        };
    }, []);
    return [selectedGroupNavigationTab, setGroupNavigationTab];
}