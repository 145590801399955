import React from "react"

import "./ScheduleState.css";

export const ScheduleState = (props) => {
    let state = "";
    if (props.state === 1) {
        state = "On";
    }
    else if (props.state === 2) {
        state = "Off";
    }
    else {
        state = "Error";
    }
    return <span className={`stateContainer state${state}`}>{state}</span>;
}