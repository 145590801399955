import { useState, useEffect } from 'react';

let listeners = [];
let locationsIsOpened = false;
const setState = (toogleLocations) => {
    locationsIsOpened = toogleLocations;
    listeners.forEach((listener) => {
        listener(locationsIsOpened);
    });
};

export const useLocations = () => {
    const newListener = useState()[1];
    useEffect(() => {
        listeners.push(newListener);
        return () => {
            listeners = listeners.filter(listener => listener !== newListener);
        };
    }, []);
    return [locationsIsOpened, setState];
}