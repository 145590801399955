import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';


import "./AddNewScheduleComponent.css"
const useStyles = makeStyles((theme) => ({
    button: {
      color: "#2160b5",
      border: "1px solid #2160b5",
    },
  }));

export const AddNewScheduleComponent = (props)=>{
    const classes = useStyles();
    return (
        <div className={`event`} key={"addnewSchedule"} >
           <Button variant="outlined" className={classes.button} startIcon={<AddIcon />} size="small" color="primary" onClick={props.onAddNewPumpScheduleClick}>
            Pump
          </Button>
          <Button variant="outlined" className={classes.button} startIcon={<AddIcon />} size="small" color="primary" onClick={props.onAddNewEventClick}>
            Group
          </Button>
        </div>
    );
}