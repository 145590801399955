import React from "react";
import { Polygon, Marker } from 'react-google-maps';
import useGlobal from "../../store/index";
import Water from "../../assets/img/water-2@2x.png";
import ErrorIcon from "../../assets/img/ic-info.png";
import useMobileTrackerGroupSelect from "../../hooks/useMobileTrackerGroupSelect";
import useMobileGroupNavigation from "../../hooks/useMobileGroupNavigation";
import { useBottomNavigation } from "../../hooks/useBottomNavigation";
import useWindowDimensions from '../../hooks/useWindowDimensions';



export const Polygones = (props) => {
    const [trackerGroups] = useGlobal(
        state => state.trackerGroups
    )
    const [selectedBottonNavigatonTab, setBottomNavigationTab] = useBottomNavigation();
    const [selectedGroupNavigationTab, setGroupNavigationTab] = useMobileGroupNavigation();
    const [selectedMobileTrackerGroup, setMobileTrackerGroup] = useMobileTrackerGroupSelect();
    const { isMobileSize } = useWindowDimensions();

    const [locker, setSelectGroup] = useGlobal(
        state => state.locker,
        actions => actions.trackerGroup.useSelectGroup
    );

    const [trackersForMap, trackerService] = useGlobal(
        state => state.trackersForMap,
        actions => actions.trackers.trackerService
    )

    const { setSelectTrackerV2 } = trackerService();

    if (!trackersForMap || !trackersForMap.data || !trackersForMap.data.length > 0 || !Array.isArray(trackersForMap.data))
        return "";

    return (
        <div>
            {

                trackersForMap.data.map((item, i) => {
                    const group = trackerGroups.data.find(group => item.group.id == group.id);
                    if (!group) {
                        return "";
                    }
                    const path = item.location;
                    let bounds = new window.google.maps.LatLngBounds();
                    if (Array.isArray(path)) {
                        path.forEach(element => {
                            bounds.extend(element);
                        });
                    }

                    const center = bounds.getCenter();

                    let marker = "";
                    if (item.state == 0) {
                        marker = <Marker position={center} clickable={true} icon={ErrorIcon} onClick={() => { alert("Someting went wrong") }} />;
                    }
                    else if (item.state == 1) {
                        marker = <Marker position={center} icon={Water} />
                    }

                    //console.log(this.polygonRef);

                    return (<div key={i}>
                        <Polygon

                            onClick={
                                () => {
                               
                                    setMobileTrackerGroup(group);
                                    setSelectGroup(group.id, item.id);
                                    let trs = trackersForMap.data.find(ss => ss.id == item.id);
                                    setSelectTrackerV2(trs)
                                    if(isMobileSize){
                                        setBottomNavigationTab("group");
                                        setGroupNavigationTab("trackerdetils");
                                    }
                                }
                            }
                            key={i}
                            path={path}
                            options={{ fillColor: group.color, strokeColor: group.color }} >
                        </Polygon>
                        {marker}
                    </div>)
                })
            }
        </div>
    )
}