
import { apiProxy } from "../hooks/apiProxy";

export const groupScheduleService = (store) => {
    const { get, post, patch, remove } = apiProxy();

    const getGroupSchedulesInCurrentTerritory = async () => {
        const schedules = {
            data: [],
            isLoading: true
        }
        store.setState({ schedules });

        try {
            const response = await get(`territories/${store.state.currentTerritoryId}/groupschedules`);
            const schedules = {
                data: response,
                isLoading: false
            }

            store.setState({ schedules });
        } catch (error) {
            console.log(error);
            const schedules = {
                data: [],
                isLoading: false
            }
            store.setState({ schedules });
        }
    }

    const createGroupSchedule = async (payload) => {
        try {
            return await post("groupschedules/", payload);
        } catch (error) {
            throw error;
        }
    }

    const updateGroupSchedule = async (payload) => {
        try {        
            //payload.tracker_groups = [payload.tracker_groups[0].id];
            console.log(payload);
            return await patch(`groupschedules/${payload.id}/`, payload);
        } catch (error) {
            throw error;
        }
    }

    const deleteGroupSchedule = async (scheduleId) => {
        return await remove(`groupschedules/${scheduleId}/`);
    }

    const pushNewScheduleInState = (schedule) => {
        const newSchedules = { ...store.state.schedules };
        //const groups = {...store.state.trackerGroups};
        newSchedules.data.push(schedule);

        store.setState({ schedules: newSchedules });
    }

    const updateScheduleInState = (schedule) => {
        const newSchedules = { ...store.state.schedules };

        for (let i = 0; i < newSchedules.data.length; i++) {
            if (newSchedules.data[i].id == schedule.id) {
                newSchedules.data[i].is_enabled = schedule.is_enabled;
                newSchedules.data[i].title = schedule.title;
                newSchedules.data[i].start_time = schedule.start_time;
                newSchedules.data[i].end_time = schedule.end_time;
                newSchedules.data[i].start_date = schedule.start_date;
                newSchedules.data[i].end_date = schedule.end_date;
                newSchedules.data[i].days = schedule.days;
                newSchedules.data[i].tracker_groups = schedule.tracker_groups;
            }
        }

        store.setState({ schedules: newSchedules });
    }

    const removeScheduleFromState = (scheduleId) => {
        const newSchedules = { ...store.state.schedules };

        newSchedules.data = newSchedules.data.filter((e) => e.id != scheduleId);

        store.setState({ schedules: newSchedules });
    }

    return { getGroupSchedulesInCurrentTerritory, createGroupSchedule, updateGroupSchedule, deleteGroupSchedule, pushNewScheduleInState, updateScheduleInState, removeScheduleFromState };
}