import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, Button, Select, InputLabel } from "@material-ui/core";

import useGlobal from "../../../store/index";

import "../InfoWindowWrapper.css"


const useStyles = makeStyles((theme) => ({

    buttonCancel: {
        color: "#5DBA49",
        border: "1px solid #5DBA49",
        "&:focus": {
            outline: "none"
        },
        ":hover": {
            border: "1px solid #5DBA49",
        }
    },
    buttonSave: {
        color: "#FFFFFF",
        backgroundColor: "#5DBA49",
        ":hover": {
            backgroundColor: "#5DBA49",
        }
    }
}));

export const EditState = (props) => {
    const classes = useStyles();
    const [title, setTitle] = useState(props.tracker.title);
    const [description, setDescription] = useState(props.tracker.description);
    const [groupId, setGroupId] = useState(props.tracker.group.id);
    const [minPreasure, setMinPreasure] = useState(props.tracker.minPreasure);
    const [maxPreasure, setMaxPreasure] = useState(props.tracker.maxPreasure);
    const [trackerGroupData] = useGlobal(
        state => state.trackerGroups
    );

    const onTitleChange = (event) => {
        setTitle(event.target.value);
    }

    const onDescrtionChange = (event) => {
        setDescription(event.target.value);
    }

    const onMinPreasureChenged = (event)=>{
        setMinPreasure(event.target.value);
    }

    const onMaxPreasureChenged = (event)=>{
        setMaxPreasure(event.target.value);
    }

    const onGroupSelectChange = (event) => {
        setGroupId(event.target.value);
    }

    return (
        <div className="edit-state-window">
            <div>
                <TextField
                    style={{ width: "100%" }}
                    error={title === ""}
                    id="title"
                    name="title"
                    size="small"
                    onChange={onTitleChange}
                    value={title}
                    label={`Title`}
                    variant="outlined" />
            </div>
            <div style={{ margin: "16px 0" }}>
                <TextField
                    style={{ width: "100%" }}
                    error={description === ""}
                    id="description"
                    name="description"
                    size="small"
                    onChange={onDescrtionChange}
                    value={description}
                    label="Description"
                    variant="outlined" />
            </div>
            <div style={{maxWidth:"230px",display:"flex", marginBottom:"10px"}}>
                <TextField
                    error={minPreasure === ""}
                    id="min"
                    name="min"
                    size="small"
                    onChange={onMinPreasureChenged}
                    value={minPreasure}
                    label="Min pressure"
                    className="mr-1"
                    variant="outlined" />

                <TextField

                    error={maxPreasure === ""}
                    id="max"
                    name="max"
                    size="small"
                    onChange={onMaxPreasureChenged}
                    value={maxPreasure}
                    label="Max pressure"
                    variant="outlined" />
            </div>
            <div className="mb-2 justify-content-center align-items-center">
                <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="demo-dialog-native">Group</InputLabel>
                    <Select
                        native
                        value={groupId}
                        onChange={onGroupSelectChange}
                    >
                        {trackerGroupData.data.map((item, i) => {
                            let selected = ""
                            if (item.id == groupId) {
                                selected = "selected"
                            }
                            return <option selected={selected} key={i} value={item.id}>{item.name}</option>
                        })}
                    </Select>
                </FormControl>
            </div>

            <div style={{ marginTop: "24px", display: "flex", justifyContent: "space-around" }}>
                <Button className={classes.buttonCancel} onClick={props.onEditCancel} variant="outlined" >Cancel</Button>
                <Button className={classes.buttonSave} onClick={() => { props.onEditSave(title, description, groupId, minPreasure, maxPreasure); }} variant="contained">Save</Button>
            </div>
        </div>
    )
}