import React, { useState } from "react";
import { makeStyles, Button, Select, InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

import "../InfoWindowWrapper.css";

const useStyles = makeStyles((theme) => ({

    buttonCancel: {
        color: "#5DBA49",
        border: "1px solid #5DBA49",
        "&:focus": {
            outline: "none"
        },
        ":hover": {
            border: "1px solid #5DBA49",
        }
    },
    buttonSave: {
        color: "#FFFFFF",
        backgroundColor: "#5DBA49",
        ":hover": {
            backgroundColor: "#5DBA49",
        }
    }
}));

export const ManualStartState = (props) => {
    const classes = useStyles();

    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);

    const hours = [];
    for (let i = 0; i < 24; i++) {
        hours.push({ value: i, label: i });
    };

    const minutes = [];
    for (let i = 0; i < 60; i++) {
        minutes.push({ value: i, label: i });
    };

    const onHourSelectChange = (event) => {
        setHour(event.target.value);
    }

    const onMinuteSelectChange = (event) => {
        setMinute(event.target.value);
    }
    
    return (
        <div className="manual-start-state">
            <div className="manual-start-state-title">
                Start manually
            </div>
            <div className="manual-start-state-duration">
                <FormControl>
                    <InputLabel htmlFor="demo-dialog-native">Hour</InputLabel>
                    <Select
                        native
                        value={hour}
                        onChange={onHourSelectChange}
                    >
                        {hours.map((item, i) => {
                            let selected = ""
                            if (item.value == hour) {
                                selected = "selected"
                            }
                            return <option selected={selected} key={i} value={item.value}>{item.label}</option>
                        })}
                    </Select>
                </FormControl>

                <FormControl>
                    <InputLabel htmlFor="demo-dialog-native">Minute</InputLabel>
                    <Select
                        native
                        value={minute}
                        onChange={onMinuteSelectChange}
                    >
                        {minutes.map((item, i) => {
                            let selected = ""
                            if (item.value == minute) {
                                selected = "selected"
                            }
                            return <option selected={selected} key={i} value={item.value}>{item.label}</option>
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="manual-start-state-action" >
                <Button className={classes.buttonCancel} onClick={props.onCancel} variant="outlined" >Cancel</Button>
                <Button className={classes.buttonSave} onClick={() => { props.onOpen({ hour, minute }); }} variant="contained">Open</Button>
            </div>
        </div>
    )
}