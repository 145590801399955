import React, { useEffect } from "react";
import useGlobal from "../../store";

import './NetworkInformation.css'

export const NetworkInformation = (props) => {
    const [networkInfo, networkInfoService] = useGlobal(
        state => state.networkInfo,
        action => action.networkInfo.networkInfoService
    );

    const { getNetworkInfo, setMessageBrokerState, setServerState } = networkInfoService();
    const [hubConnection] = useGlobal(
        state => state.hubConnection
    );

    const [currentTerritoryId] = useGlobal(
        state => state.currentTerritoryId
    );

    useEffect(() => {
        getNetworkInfo();

        if (hubConnection) {
          //  //hubConnection.invoke("MessageBrokerState");
          //  hubConnection.send(JSON.stringify({
          //      message: {
          //          action: "messagebrokerstate",
          //          data: {}
          //      }
          //  }));
          //  //hubConnection.invoke("ServerState", currentTerritoryId);
          //  hubConnection.send(JSON.stringify({
          //      message: {
          //          action: "serverstate",
          //          data: {
          //              territoryId: currentTerritoryId
          //          }
          //      }
          //  }));
        }
    }, [hubConnection]);


    // if (hubConnection) {
    //     hubConnection.onmessage = (event) => {              
    //         let data = JSON.parse(event.data);    
    //         switch (data.message.action) {
    //             case "messagebrokerstate":
    //                 setMessageBrokerState(data.message.data.mqttinfoisonline);
    //                 break;
    //             case "serverstate":
    //                 setServerState(data.message.data.localnetworkisonline)
    //                 break;
    //         }
    //     }

    //     // hubConnection.on("MessageBrokerState", function (d) {
    //     //     setMessageBrokerState(d);
    //     // })

    //     // hubConnection.on("ServerState", function (d) {
    //     //     setServerState(d);
    //     // })
    // }

    return (<div className={"network-info-wrapper"}>
        <div className={"network-info"}>
            <div className={"network-info-item"}>
                <span className={`indicator ${networkInfo.mqqttInfoIsOnline === 1 ? "online" : networkInfo.mqqttInfoIsOnline === 2 ? "error" : ""}`}></span>
                <span className={""}>Global Server</span>
            </div>
            <div className={"network-info-item"}>
                <span className={`indicator ${networkInfo.localNetworkIsOnline === 1 ? "online" : networkInfo.localNetworkIsOnline === 2 ? "error" : ""}`}></span>
                <span className={""}>Local Server</span>
            </div>
        </div>
    </div>);
}