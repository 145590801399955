import { useState, useEffect } from 'react';

let listeners = [];
let selectedTab = "";
const setState = (newTab) => {
    selectedTab = newTab;
    listeners.forEach((listener) => {
        listener(selectedTab);
    });
};

export const useBottomNavigation = () => {
    const newListener = useState()[1];
    useEffect(() => {
        listeners.push(newListener);
        return () => {
            listeners = listeners.filter(listener => listener !== newListener);
        };
    }, []);
    return [selectedTab, setState];
}