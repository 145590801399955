import React, { useState } from 'react';

import { makeStyles, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, Button } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({

    button: {
        color: "#2160b5",
        border: "1px solid #2160b5",
        "&:focus": {
            outline: "none"
        }
    },
    buttonStop: {
        color: "#ff0000",
        border: "1px solid #ff0000",
        "&:focus": {
            outline: "none"
        }
    }
}));

export const StopAllConfirmation = (props) => {
    const classes = useStyles();

    const handleClose = () => {
        props.toggleShowStopAllConfirmationModal(false)
    };

    const handleConfirm = () => {
        props.onConfirmStopAll();
        props.toggleShowStopAllConfirmationModal(false)
    }

    return (
        <Dialog
            open={props.showConfirmationModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Stop All?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure want stop all?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={handleClose} color="primary">Cancle</Button>
                <Button className={classes.buttonStop} onClick={handleConfirm} color="secondary" startIcon>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}