import React from 'react';
import MenuExtended from '../../assets/img/menu-expanded.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { makeStyles, Fab } from "@material-ui/core";
import useGlobal from "../../store/index";

import "./MenuToggleButton.css";

const useStyles = makeStyles((theme) => ({
    fab:{
      backgroundColor:"#2160b5",
      "&:hover":{
        backgroundColor:"#083a7b"
      },
      "&:focus":{
        outline:"none"
      }
    }
  }));


export const MenuToggleButton = () => {
    const classes = useStyles();
  
    const { isMobileSize } = useWindowDimensions();

    const [menuIsOpen, toggleMenu] = useGlobal(
        state => state.menuIsOpen,
        actions => actions.navigation.useToggleMenu
    )


    return (
        isMobileSize ?
            <div className="sidebar_show_button">
                <Fab className={classes.fab} color="primary" onClick={() => { toggleMenu(!menuIsOpen); }}>
                    <img src={MenuExtended} />
                </Fab>
            </div>
            : ""
    );
}