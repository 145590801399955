import React from 'react';
import './UserAccountComponent.css'

import LinerChart from '../../assets/img/UserAccount/iconfinder_line-chart.png';
import UserAvatar from '../../assets/img/UserAccount/iconfinder_user-avatar.png';
import UserProfile01 from '../../assets/img/UserAccount/userprofile01.png';
import SaveIcon from '../../assets/img/UserAccount/saveicon.png';

export const UserAccountComponent = () => {
    return <div className={'user_block'} >
        <h4>
            My Account Details
            <span>Check and edit user account information, including password, plans and contractors</span>
        </h4>
        <div className={'row justify-content-center'}>
            <div className="col-5 setting_user_block">
                <div>
                    <h6>User Settings</h6>
                    <div className="blocks user_setting">
                        <div>
                            <img src={LinerChart} alt="" />
                            <div>
                                <span>Units Of Measurement</span>
                                <p>Celsius &amp; Milimeters</p>
                            </div>
                        </div>
                        <div>
                            <img src={UserProfile01} />
                            <div><span>Langue</span><p>English</p></div>
                        </div>
                    </div>
                </div>
                <div className={'user_info'}>
                    <div className="user_image">
                        <img src={UserAvatar} className="vertical_horizontal_center" alt="" />
                    </div>
                    <div className="user_name">
                        <p>Sergey</p>
                        <span>sergeybarkudaryan@gmail.com</span>
                    </div>
                </div>
                <div className="settins_block_bottom">
                    <div className="edit_btn">
                        <img src={SaveIcon} alt="" />
                    </div>
                </div>
            </div>     
        </div>
    </div>;
}