import React from 'react';
import { ScheduleMobile } from "./ScheduleMobile";
import { GroupsMobile } from "./GroupsMobile";
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const BottomActionsMobileWrapper = () => {
    const { width, height, isMobileSize } = useWindowDimensions();

    if (!isMobileSize)
        return "";

    return (
        <div>
            <GroupsMobile/>
            <ScheduleMobile />
        </div>
    )
}