import React from "react";
import LiManomenter from '../../assets/img/il-manomenter.png';
import './PumpComponent.css';

import { GaugeChart } from "../GaugeChart/GaugeChartComponent"

export const PumpComponent = (props) => {
    // const classes = useStyles();
    // const [open, setOpen] = useState(false);

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const onDeleteClick = () => {
    //     handleClose();
    //     props.onDelete(props.id);
    // }

    return (
        <div className={`col pump ${props.turnedOn == '1' ? "block-on" : "block-off"}`}  >
            {/* <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className={classes.speedDial}
                icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                onClose={handleClose}
                onOpen={handleOpen}
                direction="down"
                open={open}
            >
                <SpeedDialAction
                    key={"Delete"}
                    icon={<DeleteForeverIcon fontSize="small" />}
                    tooltipTitle={"Delete"}
                    onClick={onDeleteClick}
                />
            </SpeedDial> */}
            <p>{props.title}</p>
            <span className="on_off">{props.turnedOn == '1' ? "ON" : "OFF"}</span>
            <div className="manomenter-block">
                <GaugeChart pressure={props.pressure} id={props.id} />
            </div>
            {/* <span className={"number green"}>1.0</span> */}
        </div>);

}