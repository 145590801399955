
import { apiProxy } from "../hooks/apiProxy";
export const pumpService = (store) => {

    const { get } = apiProxy();

    const getInCurrentTerritory = async () => {
        const pumps = {
            data: [],
            isLoading: true
        }
        store.setState({ pumps });
        try {
            const response = await get(`territories/${store.state.currentTerritoryId}/pumps`);
            const pumps = {
                data: response,
                isLoading: false
            }

            store.setState({ pumps });
        } catch (error) {
            console.log(error);
            const pumps = {
                data: [],
                isLoading: false
            }
            store.setState({ pumps });
        }
    }

    const setPumpPressure = (pump) => {

        const newPumps = { ...store.state.pumps };

        for (let i = 0; i < newPumps.data.length; i++) {
            if (newPumps.data[i].id == pump.id) {
                newPumps.data[i].pressure = pump.pressure;
            }
        }

        store.setState({ pumps: newPumps });

    }

    const setPumpState = (pump) => {
        const newPumps = { ...store.state.pumps };
        for (let i = 0; i < newPumps.data.length; i++) {
            if (newPumps.data[i].node_id == pump.node_id) {
                newPumps.data[i].state = pump.state;
            }
        }

        store.setState({ pumps: newPumps });
    }



    return { getInCurrentTerritory, setPumpPressure,setPumpState };
}