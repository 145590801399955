import {useState, useEffect} from 'react'
import { createTokenProvider } from "./createTokenProvider";

const tokenProvider = createTokenProvider();

export const createAuthProvider = () => {

    /* Implementation */
    const login = (newTokens) => {
        tokenProvider.setToken(newTokens);
    };
    
    const logout = () => {
        tokenProvider.setToken(null);
    };

    const authFetch = async (input, init) => {
        const token = await tokenProvider.getToken();
    
        init = init || {};
    
        init.headers = {
            ...init.headers,
            Authorization: `Bearer ${token}`,
        };
    
        return fetch(input, init);
    };

    const useAuth = () => {
        const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());
    
        useEffect(() => {
            const listener = (newIsLogged) => {
                setIsLogged(newIsLogged);
            };
    
            tokenProvider.subscribe(listener);
            return () => {
                tokenProvider.unsubscribe(listener);
            };
        }, []);
    
        return [isLogged];
    };

    const isLoggedIn = ()=>{
        return tokenProvider.isLoggedIn();
    }

    return {
        useAuth,
        authFetch,
        login,
        logout,
        isLoggedIn
    }
};