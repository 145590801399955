import React, { useState, useEffect } from "react";
import { MainBlockGroupList } from "./MainBlockGroupList";
import { MapWithGroundOverlay } from "../Map/Map";
import { BottomActionsMobileWrapper } from "./BottomActionsMobileWrapper";
import useGlobal from "../../store";

import "./MainBlock.css";

export const MainBlock = (props) => {
    const [currentTerritoryId, territoryService] = useGlobal(
        store => store.currentTerritoryId,
        actions => actions.territory.territoryService
    );

    const [mapCenterPosition ] = useGlobal(
        store => store.mapCenterPosition,
    );

    //const [defaultCenter, setDefaultCenter] = useState({ lat: 40.08925, lng: 44.6498 });

    const { getById, getTrackerServer, } = territoryService();

    const [trackerServer] = useGlobal(
        state => state.trackerServer
    );

    useEffect(() => {
        getById(currentTerritoryId);
        getTrackerServer(currentTerritoryId);
        // if (trackerServer) {
        //     setDefaultCenter({ lat: trackerServer.lat, lng: trackerServer.lng });
        // }
    }, [mapCenterPosition])


    return (
        <div className="card main_block container-fluid">
            <div className="row">
                <div className="col-3 groups_block d-none d-lg-block">
                    <MainBlockGroupList />
                </div>
                <div className="col-lg-9 map_block">
                    <div id="map">
                        <MapWithGroundOverlay defaultCenter={mapCenterPosition} />
                    </div>
                </div>
            </div>
            <BottomActionsMobileWrapper />
        </div>
    );
}

