import React, { useState, useEffect } from "react";
import { makeStyles, Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@material-ui/core";
import { Select, FormControl, InputLabel, TextField, IconButton } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import useGlobal from "../../store";
import "./CreateShcedule.css";
import Moment from "moment";

Array.prototype.remove = function () {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        marginBottom: 10
    },
    button: {
        color: "#2160b5",
        border: "1px solid #2160b5",
        "&:focus": {
            outline: "none"
        }
    },
    weekDay: {
        width: 40,
        height: 40,
        margin: 2,
        "&:focus": {
            outline: "none"
        }
    },
    weekDaySelected: {
        backgroundColor: "#2160b5",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#263c58",
            color: "#ffffff",
        }
    }
}));


export const CreateShcedule = (props) => {


    const classes = useStyles();
    const [scheduleTitle, setScheduleTitle] = useState("");
    const [scheduleDescription, setScheduleDescription] = useState("");
    const [selectedTrackerGroupId, setSelectedTrackerGroupId] = useState(undefined);
    const [startDateTime, setStartDateTime] = useState(undefined);
    const [endDateTime, setEndDateTime] = useState(undefined);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [locker, setLocker] = useState(0);
    const [trackerGroupData] = useGlobal(
        state => state.trackerGroups
    );


    const [schedules, groupScheduleService] = useGlobal(
        state => state.schedules,
        action => action.groupSchedules.groupScheduleService
    )
    const { getGroupSchedulesInCurrentTerritory, createGroupSchedule, updateGroupSchedule } = groupScheduleService();

    const [pumpSchedules, pumpScheduleService] = useGlobal(
        state => state.pumpSchedules,
        action => action.pumpSchedules.pumpScheduleService
    )

    const [currentTerritoryId] = useGlobal(
        store => store.currentTerritoryId
      );

    const { getPumpSchedulesInCurrentTerritory } = pumpScheduleService();

    useEffect(() => {
        let initDate = new Date();
        setStartDateTime(initDate);
        setEndDateTime(initDate);
    }, []);

    useEffect(() => {
        if (props.schedule) {
            setScheduleTitle(props.schedule.title);
            setScheduleDescription(props.schedule.description);
            setSelectedTrackerGroupId(props.schedule.tracker_groups[0].id);
            setStartDateTime(new Date(props.schedule.start_date + " " + props.schedule.start_time));
            setEndDateTime(new Date(props.schedule.end_date + " " + props.schedule.end_time));
            setSelectedWeekDays(props.schedule.days);
        }
    }, [props.schedule]);

    useEffect(() => {
        if (!trackerGroupData || !trackerGroupData.data || !Array.isArray(trackerGroupData.data) || trackerGroupData.data.length == 0)
            return;
        setSelectedTrackerGroupId(trackerGroupData.data[0].id);
    }, [trackerGroupData]);

    const resetFormData = () => {
        setScheduleTitle("");
        setScheduleDescription("");
        setSelectedTrackerGroupId(undefined);
        setStartDateTime(undefined);
        setEndDateTime(undefined);
        setSelectedWeekDays([]);
    }

    const formIsValid = () => {
        return true;
    }

    const handleOnSaveClick = () => {
        let payload = {
            title: scheduleTitle,
            description: scheduleDescription,
            start_date: Moment(startDateTime).format("YYYY-MM-DD"),
            start_time: Moment(startDateTime).format("HH:mm:ss"),
            end_date: Moment(endDateTime).format("YYYY-MM-DD"),
            end_time: Moment(endDateTime).format("HH:mm:ss"),
            tracker_groups: [parseInt(selectedTrackerGroupId)],
            days: selectedWeekDays,
            territory: currentTerritoryId
        };

        if (props.schedule) {        
            payload.id = props.scheduleId; 
            const trackerGroupId = parseInt(selectedTrackerGroupId);
            payload.tracker_groups= [trackerGroupId];
            
            updateGroupSchedule(payload).then(() => {
                alert("Updated");
            }).catch((err) => {
                console.log(err);
                alert("Someting went wrong(");
            })
            .finally(() => {
                props.toggleShowAddNewScheduleModal(false);
            });
        }
        else {
            createGroupSchedule(payload).then(() => {
                alert("Added");
            }).catch((err) => {
                console.log(err);
                alert("Someting went wrong(");
            })
            .finally(() => {
                props.toggleShowAddNewScheduleModal(false);
            });
        }

    }

    const handleOnClose = () => {
        resetFormData();
        props.toggleShowAddNewScheduleModal(false);
    }

    const handleDialogClose = () => {
        handleOnClose();
    }

    const handleOnGroupSelectChange = (event) => {
        setSelectedTrackerGroupId(event.target.value);
    }

    const onScheduleTitleChange = (event) => {
        setScheduleTitle(event.target.value);
    }

    const onScheduleDescriptionChange = (event) => {
        setScheduleDescription(event.target.value);
    }

    const handleStartDateTime = (date) => {
        setStartDateTime(date)
    }

    const handleEndDateTime = (date) => {
        setEndDateTime(date);
    }

    const handleWeekDayClick = (weekDay) => {
        let tmpList = selectedWeekDays;
        if (selectedWeekDays.some(item => item == weekDay)) {
            const index = tmpList.indexOf(weekDay);

            if (index > -1) {
                tmpList.splice(index, 1);
            }
        }
        else {
            tmpList.push(weekDay);
        }
        setSelectedWeekDays(tmpList);
        setLocker(locker + 1);
    }

    if (!trackerGroupData || !trackerGroupData.data || !Array.isArray(trackerGroupData.data))
        return "";



    return (
        <Dialog open={props.show} onClose={handleOnClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add new group schedule</DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-dialog-native">Group</InputLabel>
                    <Select
                        native
                        value={selectedTrackerGroupId}
                        onChange={handleOnGroupSelectChange}
                    >
                        {trackerGroupData.data.map((item, i) => {
                            return <option key={i} value={item.id}>{item.name}</option>
                        })}
                    </Select>
                </FormControl>
                <div className="row">
                    <div className="col-md-6">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Title"
                            variant="outlined"
                            value={scheduleTitle}
                            onChange={onScheduleTitleChange}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            variant="outlined"
                            value={scheduleDescription}
                            onChange={onScheduleDescriptionChange}
                            fullWidth
                        />
                    </div>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="row">
                        <div className="col-md-6">
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                format="MM/dd/yyyy HH:mm"
                                margin="normal"
                                id="start-date"
                                label="Start date"
                                value={startDateTime}
                                onChange={handleStartDateTime}
                                autoOk
                            />
                        </div>
                        <div className="col-md-6">
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                format="MM/dd/yyyy HH:mm"
                                margin="normal"
                                id="end-date"
                                label="End date"
                                value={endDateTime}
                                onChange={handleEndDateTime}
                                autoOk
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-2">
                            <div className="row justify-content-center align-items-center">
                                <IconButton onClick={() => { handleWeekDayClick(1); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 1) ? classes.weekDaySelected : ""}`}>M</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(2); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 2) ? classes.weekDaySelected : ""}`}>T</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(3); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 3) ? classes.weekDaySelected : ""}`}>W</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(4); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 4) ? classes.weekDaySelected : ""}`}>T</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(5); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 5) ? classes.weekDaySelected : ""}`}>F</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(6); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 6) ? classes.weekDaySelected : ""}`}>S</IconButton>
                                <IconButton onClick={() => { handleWeekDayClick(0); }} className={`${classes.weekDay} ${selectedWeekDays.some(item => item == 0) ? classes.weekDaySelected : ""}`}>S</IconButton>
                            </div>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={handleDialogClose} variant="outlined" color="primary">Cancel</Button>
                <Button className={classes.button} onClick={handleOnSaveClick} disabled={!formIsValid()} variant="outlined" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}
