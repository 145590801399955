import { apiProxy } from "../hooks/apiProxy";

export const historyService = (store) => {

    const { get,getExt, post } = apiProxy();
    function between(from, to) {        
        return this.filter((x, i) => {
            if (i > (from - 1) && i <= (to - 1)) { return true }
        });
    }

    Array.prototype.between = between;

    const data = [
        {
            title: "Event Name 1",
            name: "Block 1",
            start_date: "12:00 12/19/2019",
            end_date: "18:00 12/19/2019",
            notes: "Lorem Ipsum is simply dum…text of the printi",
            error: ""
        },
        {
            title: "Event Name 1",
            name: "Block 1",
            start_date: "12:00 12/19/2019",
            end_date: "18:00 12/19/2019",
            notes: "Lorem Ipsum is simply dum…text of the printi",
            error: "Something went wrong"
        },
        {
            title: "Event Name 1",
            name: "Block 1",
            start_date: "12:00 12/19/2019",
            end_date: "18:00 12/19/2019",
            notes: "Lorem Ipsum is simply dum…text of the printi",
            error: ""
        },
        {
            title: "Event Name 1",
            name: "Block 1",
            start_date: "12:00 12/19/2019",
            end_date: "18:00 12/19/2019",
            notes: "Lorem Ipsum is simply dum…text of the printi",
            error: ""
        },
        {
            title: "Event Name 1",
            name: "Block 1",
            start_date: "12:00 12/19/2019",
            end_date: "18:00 12/19/2019",
            notes: "Lorem Ipsum is simply dum…text of the printi",
            error: ""
        },
        {
            title: "Event Name 1",
            name: "Block 1",
            start_date: "12:00 12/19/2019",
            end_date: "18:00 12/19/2019",
            notes: "Lorem Ipsum is simply dum…text of the printi",
            error: ""
        },
    ];

    const getHistory = async (location, page, count) => {        
        const history = {
            data: [],
            isLoading: true,
            totalCount: 0,
            currentPage: 0,
            itemsInPerPage: 10,
        }
        store.setState({ history });
        console.log(page, count);
        try {

          var payload = {
                from:(page) * count,
                count:count,
                territoryId: location
              }

            const response = await post(`api/history/statuses`,payload);

            const history = {
                data: response.data,
                isLoading: false,
                totalCount: 0,
                currentPage: 0,
                itemsInPerPage: 10,
            }
            store.setState({ history });
        } catch (error) {
            console.log(error);
            const history = {
                data: [],
                isLoading: false,
                totalCount: 0,
                currentPage: 0,
                itemsInPerPage: 2,
            }
            store.setState({ history });
        }
    }

    return { getHistory };
}