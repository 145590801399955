import React, { useState, useRef, useEffect } from 'react';
import { ScheduleComponent } from '../Schedule/ScheduleComponent';
import { AddNewScheduleComponent } from '../Schedule/AddNewScheduleComponent';

import Slider from "react-slick";
import NavigationArrow from '../Common/NavigationArrow';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LiCalendar from '../../assets/img/il-calendar.png'
import './ScheduleList.css'
import { CircularProgress } from '../Common/CircularProgress';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { CreateShcedule } from "../Schedule/CreateShcedule";
import { DeleteConfirmation } from "../Schedule/DeleteConfirmation";
import { CreatePumpSchedule } from "../Pumps/CreatePumpSchedule";
import { DeletePumpScheduleConfirmation } from "../Pumps/DeletePumpScheduleConfirmation";
import useGlobal from "../../store";



const useStyles = makeStyles((theme) => ({
  button: {
    color: "#2160b5",
    border: "1px solid #2160b5",
  },
}));

export const ScheduleList = () => {
  const classes = useStyles();
  const [showLeftArrow, setShowLeftArrow] = useState(true);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showAddNewScheduleModal, toggleShowAddNewScheduleModal] = useState(false);
  const [showDeleteConfirmationModal, toggleShowDeleteConfirmationModal] = useState(false);
  const [showAddNewPumpScheduleModal, toggleShowAddNewPumpScheduleModal] = useState(false);
  const [showScheduleDeleteConfirmationModal, toggleShowPumpScheduleDeleteConfirmationModal] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(undefined);
  const [selectedSchedule, setSelectedSchedule] = useState(undefined);

  const [selectedPumpScheduleId, setSelectedPumpScheduleId] = useState(undefined);
  const [selectedPumpSchedule, setSelectedPumpSchedule] = useState(undefined);

  const refContainer = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
    draggable: true,
  };

  const [schedules, groupScheduleService] = useGlobal(
    state => state.schedules,
    action => action.groupSchedules.groupScheduleService
  )
  const [trackerSchedules, trackerScheduleService] = useGlobal(
    state => state.trackerSchedules,
    action => action.trackerSchedules.trackerScheduleService
  )

  const { getGroupSchedulesInCurrentTerritory, updateGroupSchedule, deleteGroupSchedule } = groupScheduleService();
   const { getTrackerSchedulesInCurrentTerritory} = trackerScheduleService();
  const [currentTerritoryId] = useGlobal(
    store => store.currentTerritoryId
  );

  const [trackerGroupData] = useGlobal(
    state => state.trackerGroups,
  );

  const [pumps] = useGlobal(
    state => state.pumps,
  );

  const [pumpSchedules, pumpScheduleService] = useGlobal(
    state => state.pumpSchedules,
    action => action.pumpSchedules.pumpScheduleService
  )

  const { getPumpSchedulesInCurrentTerritory, deletePumpSchedule, updatePumpSchedule } = pumpScheduleService();

  const next = () => {
    refContainer.current.slickNext();
  }

  const previous = () => {
    refContainer.current.slickPrev();
  }

  const onAddNewClick = () => {
    toggleShowAddNewScheduleModal(true);
  }

  const onAddNewPumpScheduleClick = () => {
    toggleShowAddNewPumpScheduleModal(true)
  }

  useEffect(() => {
    setTimeout(() => {
      getGroupSchedulesInCurrentTerritory();
      getPumpSchedulesInCurrentTerritory();
      getTrackerSchedulesInCurrentTerritory();
    }, 1200);
  }, [currentTerritoryId])

  const handleDelete = (scheduleId) => {
    setSelectedScheduleId(scheduleId);
    toggleShowDeleteConfirmationModal(true);
  }

  const handleEdit = (schedule) => {
    setSelectedSchedule(schedule);
    setSelectedScheduleId(schedule.scheduleId);
    toggleShowAddNewScheduleModal(true);
  }

  const handlePumpScheduleEdit = (schedule) => {  
    setSelectedPumpSchedule(schedule);
    setSelectedPumpScheduleId(schedule.scheduleId);
    toggleShowAddNewPumpScheduleModal(true);
  }


  const handlePumpScheduleDelete = (scheduleId) => {
    setSelectedScheduleId(scheduleId);
    toggleShowPumpScheduleDeleteConfirmationModal(true);
  }

  const onConfirmPumpScheduleDelete = (scheduleId) => {
    deletePumpSchedule(scheduleId)
      .then(r => { })
      .catch(err => console.log(err));
    toggleShowPumpScheduleDeleteConfirmationModal(false);
  }


  const onConfirmDelete = (scheduleId) => {
    deleteGroupSchedule(scheduleId)
      .then(r => { })
      .catch(err => console.log(err));
    toggleShowDeleteConfirmationModal(false);
  }

  const handelStartStop = (entity, is_enabled, type) => {    
    entity.is_enabled = is_enabled;    
    if (type == "Pump") {
      updatePumpSchedule(entity)
        .then(r => { })
        .catch(err => console.log(err));
    }
    else {
      
      entity.tracker_groups = [entity.tracker_groups[0].id];
      updateGroupSchedule(entity)
        .then(r => { })
        .catch(err => console.log(err));
    }
  }

  let pumpSlider = "";
  pumpSlider = (
    <div className="schedule_content">
      <div className="carusel_block" id="caruselBlockSchedule">
        <div >
          <Slider ref={refContainer} {...settings} >
            {
              Array.isArray(schedules.data) ? schedules.data.map((s, i) => {

                let groupColor = "#000000";
                let groupName = "";
                if (!trackerGroupData.isLoading && Array.isArray(trackerGroupData.data)) {

                  if (Array.isArray(s.tracker_groups) && s.tracker_groups.length > 0) {

                    let group = trackerGroupData.data.find(x => x.id == s.tracker_groups[0].id);
                    if (group && group.color) {
                      groupColor = group.color;
                    }

                    if (group && group.name) {
                      groupName = group.name;
                    }
                  }
                }
                return <ScheduleComponent
                  key={i}
                  eventName={s.title}
                  title={s.title}
                  tracker_groups={s.tracker_groups}
                  scheduleId={s.id}
                  is_confirmed={s.is_confirmed}
                  nodeType={groupName}
                  days={s.days}
                  nodeColor={groupColor}
                  start_date={s.start_date}
                  start_time={s.start_time}
                  end_date={s.end_date}
                  end_time={s.end_time}
                  description={s.description}
                  onPlay={() => { handelStartStop(Object.assign({}, s), true, "TrackerGroup"); }}
                  onPause={() => { handelStartStop(Object.assign({}, s), false, "TrackerGroup"); }}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  is_enabled={s.is_enabled}
                  scheduleType={'Groups'}
                />;
              }) : ""
            }
            {
              pumpSchedules.data.map((s, i) => {
                let groupName = "";
                if (!pumps.isLoading && Array.isArray(pumps.data)) {
                  if (Array.isArray(s.pumps) && s.pumps.length > 0) {

                    let pump = pumps.data.find(x => x.id == s.pumps[0].id);
 
                    if (pump && pump.title) {
                      groupName = pump.title;
                    }
                  }                  
                }
                return <ScheduleComponent
                  key={i + 1000}
                  eventName={s.title}
                  title={s.title}
                  pumps={s.pumps}
                  scheduleId={s.id}
                  is_confirmed={s.is_confirmed}
                  nodeType={groupName}
                  nodeColor={"green"}
                  start_date={s.start_date}
                  start_time={s.start_time}
                  end_date={s.end_date}
                  end_time={s.end_time}
                  days={s.days}
                  description={s.description}
                  onPlay={() => { handelStartStop(s, true, "Pump"); }}
                  onPause={() => { handelStartStop(s, false, "Pump"); }}
                  onDelete={handlePumpScheduleDelete}
                  onEdit={handlePumpScheduleEdit}
                  is_enabled={s.is_enabled}
                  scheduleType={'Pumps'}
                />
              })
            }           
            <AddNewScheduleComponent onAddNewPumpScheduleClick={onAddNewPumpScheduleClick} onAddNewEventClick={onAddNewClick} />
          </Slider>
        </div>
      </div>
      <NavigationArrow slide={previous} isVisible={showLeftArrow} direction={"left"} />
      <NavigationArrow slide={next} isVisible={showRightArrow} direction={"right"} />
    </div>
  );

  return (
    <div className="col-lg-6 schedule card">
      {/* top block schedule */}
      <div className="schedule_top">
        <div className="float-left leftSide">
          <img src={LiCalendar} className="il-calendar" />
          <span>Today’s Schedule</span>
        </div>
        <div className="float-right">
          <CreatePumpSchedule scheduleId={selectedPumpScheduleId} schedule={selectedPumpSchedule} show={showAddNewPumpScheduleModal} toggleShowAddNewPumpScheduleModal={toggleShowAddNewPumpScheduleModal} />
          <DeletePumpScheduleConfirmation
            pumpScheduleId={selectedScheduleId}
            show={showScheduleDeleteConfirmationModal}
            onConfirmDelete={onConfirmPumpScheduleDelete}
            toggleShowDeleteConfirmationModal={toggleShowPumpScheduleDeleteConfirmationModal} />
          <CreateShcedule scheduleId={selectedScheduleId} schedule={selectedSchedule} show={showAddNewScheduleModal} toggleShowAddNewScheduleModal={toggleShowAddNewScheduleModal} />
          <DeleteConfirmation scheduleId={selectedScheduleId} show={showDeleteConfirmationModal} onConfirmDelete={onConfirmDelete} toggleShowDeleteConfirmationModal={toggleShowDeleteConfirmationModal} />
          <Button variant="outlined" style={{ marginRight: 10 }} className={classes.button} startIcon={<AddIcon />} size="small" color="primary" onClick={onAddNewPumpScheduleClick}>
            Pump
          </Button>
          <Button variant="outlined" className={classes.button} startIcon={<AddIcon />} size="small" color="primary" onClick={onAddNewClick}>
            Group
          </Button>
        </div>
      </div>
      {/* content schedule */}
      {schedules.isLoading ? <CircularProgress /> : pumpSlider}
    </div>
  );
}