import React from 'react';
import { PumpsList } from './PumpsList';
import { ScheduleList } from './ScheduleList';
import { WeatherComponent } from '../Weather/WeatherComponent'
import useWindowDimensions from "../../hooks/useWindowDimensions";

import './TopBlock.css'

export const TopBlock = (props) => {
    const  windowDimensions = useWindowDimensions();

    if (windowDimensions && windowDimensions.isMobileSize)
        return "";
    return (
        <div className={"top-blocks row"}>
            <PumpsList show={props.togglePumps} />

            <ScheduleList />

            <WeatherComponent show={props.toggleWeather} />
        </div>
    );
}