import axios from 'axios';
import Utils from '../Utils';
import { createTokenProvider } from '../providers/createTokenProvider';
import { useHistory } from 'react-router-dom';

export const apiProxy = (isApp = false) => {
    const { getToken, clearStore, refreshToken } = createTokenProvider();
    let baseUrl = ""
    if (isApp)
        baseUrl = Utils.apiAppBaseUrl;
    else
        baseUrl = Utils.apiAdminBaseUrl;

    //const history = useHistory();
    axios.interceptors.request.use(async function (config) {
        let token = await getToken()        
        if(config.url.indexOf('api/v1/auth/')<0){
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    })

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response && error.response.status === 401) {
            clearStore();
            //refreshToken();
            window.location.href = "./login";
        }
        return Promise.reject(error);
    });

    const get = (route) => {
        return axios.get(baseUrl + route)
            .then((responseJson) => {
                return responseJson.data;
            })
            .catch((error) => {
                return "";
            });
    }

    const getExt = (route, payload, contentType) => {
        return axios.get(baseUrl + route, {
            params: payload,
            headers: {
                'Content-Type': contentType,
            }
        })
            .then((responseJson) => {
                return responseJson.data;
            })
            .catch((error) => {
                return "";
            });
    }

    const put = (route, payload) => {
        return axios.put(baseUrl + route, payload)
            .then((responseJson) => {
                return responseJson;
            })
            .catch((error) => {
                return error;
            });
    }

    const patch = (route, payload) => {
        return axios.patch(baseUrl + route, payload)
            .then((responseJson) => {
                return responseJson;
            })
            .catch((error) => {
                return error;
            });
    }

    const post = (route, payload) => {
        if (payload != undefined) {

            return axios.post(baseUrl + route, payload)
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    throw error;
                });
        }
        else {

            return axios.post(baseUrl + route)
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    throw error;
                });
        }
    }

    const remove = (route) => {
        return axios.delete(baseUrl + route)
            .then(r => r)
            .catch(err => err);
    }

    const xhr = (url) => {
        return fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return {
        get,
        put,
        patch,
        post,
        remove,
        getExt
    };
}