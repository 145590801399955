import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { IconButton, Button, Divider } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { InfoWindow } from 'react-google-maps';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FormControl from "@material-ui/core/FormControl";
import { Select, InputLabel } from "@material-ui/core";
import useGlobal from "../../store/index";
import { useTrackerServer } from "../../hooks/useTrackerServer";
import { Marker } from "react-google-maps";
import "./InfoWindowWrapper.css"
import { CircularProgress } from "../Common/CircularProgress";

import { CurrentState } from "./InfoWindowSteps/CurrentState";
import { EditState } from "./InfoWindowSteps/EditState";
import { ManualStartState } from "./InfoWindowSteps/ManualStartState";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            // margin: theme.spacing(1),
            width: '25ch',
        },
    },
    form: {
        width: "100%",
    },
    iconButton: {
        padding: 2,
        fontSize: 24,

    },
    startButton: {
        color: "#2160b5"
    },
    stopButton: {
        color: "#ff5440"
    },
    formControl: {
        width: "100%"
    }
}));

export const InfoWindowWrapper = (props) => {
    const classes = useStyles();
    const windowDimensions = useWindowDimensions();

    const [selectedTracker] = useGlobal(
        state => state.selectedTracker
    );

    const [infoWindowStep, setInfoWindowStep] = useState("currentState");
    const [trackerTitle, setTrackerTitle] = useState("");
    const [trackerDescription, setTrackerDescription] = useState("");
    const [selectedInputTrackerGroupId, setSelectedTrackerGroupId] = useState(0);
    const [trackerServer, setTrackerServer] = useState({});

    const [hubWaitingList, hubWaitingListFactory] = useGlobal(
        state => state.hubWaitingList,
        actions => actions.hub.hubWaitingListFactory
    );

    const [trackerGroupData] = useGlobal(
        state => state.trackerGroups
    );

    const [selectedTrackerGroupId, setSelectGroup] = useGlobal(
        state => state.selectedTrackerGroupId,
        actions => actions.trackerGroup.useSelectGroup
    );

    useEffect(() => {
        setTrackerTitle(selectedTracker && selectedTracker.title);
        setTrackerDescription(selectedTracker && selectedTracker.description);
        setSelectedTrackerGroupId(selectedTracker && selectedTracker.group.id)
        setInfoWindowStep("currentState");
    }, [selectedTracker]);

    const onTitleChange = (event) => {
        setTrackerTitle(event.target.value);
    }

    const onDesctionChange = (event) => {
        setTrackerDescription(event.target.value);
    }

    const [infoWindowOpened, tarckerService] = useGlobal(
        state => state.infoWindowOpened,
        actions => actions.trackers.trackerService
    );

    const { updateTracker, manualStart } = tarckerService();

    const onSaveClick = (event) => {
        debugger;
        const payLoad = {
            id: selectedTracker.id,
            node_id: selectedTracker.node_id,
            title: trackerTitle,
            description: trackerDescription,
            group: parseInt(selectedInputTrackerGroupId)
        };

        updateTracker(payLoad)
            .then((r) => {
                // if (selectedInputTrackerGroupId != selectedTracker.group.id) {
                //     //getTrackersForMap();
                // }
                // //getTrackers();
                // //setSelectGroup(selectedInputTrackerGroupId, selectedTracker.id);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const onGroupSelectChange = (event) => {
        setSelectedTrackerGroupId(event.target.value);
    }
    const [selectedGroupId, setSelecteGroup] = useGlobal(
        state => state.selectedGroupId,
        actions => actions.trackerGroup.useSelectGroup
    );
    const [selectedTrackerId] = useGlobal(
        state => state.selectedTrackerId
    );

    const [infoWindowPostion] = useGlobal(
        state => state.infoWindowPostion
    );

    const onWindowClose = () => {
        setTrackerTitle(selectedTracker && selectedTracker.title);
        setTrackerDescription(selectedTracker && selectedTracker.description);
        setSelectedTrackerGroupId(selectedTracker && selectedTracker.group.id)
        setSelecteGroup(0, 0);
        setInfoWindowStep("currentState");
    }

    if (windowDimensions.isMobileSize)
        return (selectedTrackerId ? <Marker position={infoWindowPostion} /> : "");

    const onStopClick = () => {
        try {
            const { add, invokeSendMessage } = hubWaitingListFactory();
            add(selectedTracker);
            invokeSendMessage(selectedTracker, "2");
        } catch (error) {
            console.log(error);
        }
    }

    const onRefreshClick  = ()=>{
        try {
            const { add, invokeSendMessage } = hubWaitingListFactory();
            //add(selectedTracker);
            invokeSendMessage(selectedTracker, "3");
        } catch (error) {
            console.log(error);
        }
    }

    const onStartClick = () => {
        try {
            const { add, invokeSendMessage } = hubWaitingListFactory();
            add(selectedTracker);
            invokeSendMessage(selectedTracker, "1");
        } catch (error) {
            console.log(error);
        }
    }

    const onEditClick = () => { setInfoWindowStep("editState"); }
    const onManualStartClick = () => { setInfoWindowStep("manualStartState"); }
    const onEditCancel = () => { setInfoWindowStep("currentState"); }
    const onEditSave = (title, description, groupId, minPreasure, maxPreasure) => {

        const payLoad = {
            id: selectedTracker.id,
            node_id: selectedTracker.node_id,
            title: title,
            description: description,
            min_preasure: minPreasure,
            max_preasure: maxPreasure,
            group: parseInt(groupId)
        };

        updateTracker(payLoad)
            .then((r) => {
                setInfoWindowStep("currentState");
            })
            .catch(error => {
                console.log(error);
            });
    };

    const onManualStartOpen = (data) => {
        manualStart(selectedTracker, data)
            .then((r) => {
                setInfoWindowStep("currentState");
            })
            .catch(error => {
                console.log(error);
            });

    }

    const renderStep = (step) => {
        if (!selectedTracker) return;
        switch (step) {
            case "currentState":
                return <CurrentState
                    tracker={selectedTracker}
                    onStopClick={onStopClick}
                    onRefreshClick={onRefreshClick}
                    onEditClick={onEditClick}
                    onManualStartClick={onManualStartClick} />;
            case "editState":
                return <EditState
                    tracker={selectedTracker}
                    onEditCancel={onEditCancel}
                    onEditSave={onEditSave}
                />
            case "manualStartState":
                return <ManualStartState
                    tracker={selectedTracker}
                    onCancel={onEditCancel}
                    onOpen={onManualStartOpen}
                />
        }
    }



    return (
        infoWindowOpened &&
        <InfoWindow
            position={infoWindowPostion}
            options={{ closeBoxURL: ``, enableEventPropagation: true }}
            onCloseClick={() => { onWindowClose(); }}
        >
            <div className={`container-fluid`} style={{}}>
                <div className={`row`} style={{}}>
                    <form className={classes.form} noValidate autoComplete="off">
                        {renderStep(infoWindowStep)}
                    </form>
                </div>
            </div>
        </InfoWindow>)
}