import { apiProxy } from "../hooks/apiProxy";

export const companyService = (store) => {
    const { get } = apiProxy();

    const loadForCurrentCustomer = async () => {
        const companies = {
            data: [],
            isLoading: true
        }
        store.setState({ companies });
        try {
            const response = await get(`organizations`);
            const companies = {
                data: response,
                isLoading: false
            }
            store.setState({ companies });
        } catch (error) {
            console.log(error);
            const companies = {
                data: [],
                isLoading: false
            }
            store.setState({ companies });
        }
    }

    return { loadForCurrentCustomer };
}