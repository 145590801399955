export const networkInfoService = (store)=>{


    const getNetworkInfo = ()=>{
        
        const networkInfo = {
            mqqttInfoIsOnline: false,
            localNetworkIsOnline: false
        };
    
        store.setState({ networkInfo });
    }

    const setMessageBrokerState=(state)=>{
        const newNetworkInfo = {...store.state.networkInfo};

        newNetworkInfo.mqqttInfoIsOnline = state;

        store.setState({ networkInfo:newNetworkInfo });
    }

    const setServerState = (state)=>{
        const newNetworkInfo = {...store.state.networkInfo};

        newNetworkInfo.localNetworkIsOnline = state;

        store.setState({ networkInfo:newNetworkInfo });
    }

    return {getNetworkInfo, setMessageBrokerState, setServerState};
}